
import React, { useState, useContext, useEffect } from "react";
import { GlobalContext } from "../../App";
import { NavLink, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import files from "../static";
import HOST from "../host";
import OTP from "./otp";
import { logToServer } from "../logger";
function Forget() {
  const navigate = useNavigate();
  const [member_email, setUserEmail] = useState("");
  const [enter, SetEnter] = useState(false)
  const [reset, setReset] = useState(false);
  const [pass, setPass] = useState("");
  const [cPass, setCpass] = useState("");
  const { setUserInfo, userInfo } = useContext(GlobalContext);
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d#@]{8,}$/;

  // useEffect(()=>{
  //   if(userInfo){
  //     navigate("/projects")
  //   }
  // },[])

  const sendOTP = async (e) => {

    e.preventDefault();

    if (!member_email) {
      toast.warn("Please enter email");
      return;
    }
    const key = sessionStorage.getItem('optkey');
    let url = !key ? `${HOST}/send-otp/${member_email}` : `${HOST}/send-otp/${member_email}/${key}`
    const csrftoken = document.cookie.split('; ').find(row => row.startsWith('csrftoken='));
    try {
      const res = await fetch(url, {
        method: "POST",
        body: {},
      });

      const data = await res.json();

      if (res.status === 400 || res.status === 500 || !data || data.error) {
        SetEnter(false)
        logToServer('error','Error sending OTP')
        toast.error("Error sending OTP. Please try again");
      } else if (res.status === 200) {
        toast.success(`OTP sent successfully`);
        logToServer('info','OTP sent successfully')
        sessionStorage.setItem('otpkey', data.key);
        const expirationTime = new Date().getTime() + 5 * 60 * 1000;
        sessionStorage.setItem(`otp_expiration`, expirationTime);
        sessionStorage.setItem(`email`, member_email);
        SetEnter(true)
        // try {
        //   toast.success(`Welcome ${data.user_info.name}`);
        //   await new Promise(resolve => setTimeout(resolve, 2000)); // Wait for 2 seconds
        //   navigate("/projects");
        // } catch (error) {
        //   console.error("Error navigating to welcome page:", error);
        // }
        // return;
      }
    }
    catch (e) {
      toast.error("Invalid credentials");
      logToServer('error',`${e}`)
    }

  };

  async function resetPass(e) {
    e.preventDefault();
    const email = sessionStorage.getItem('email');
    if (pass && cPass && pass !== "" && cPass !== "") {
      if (pass === cPass) {
        if(passwordRegex.test(pass) && pass.length >= 8){
          try {
            const res = await fetch(`${HOST}/reset-password`, {
              method: "POST",
              body: JSON.stringify({ data: {email:email, password: pass }, },),
            });
  
            if (res.status === 400) {
              toast.error("Password reset failed");
              logToServer('error','password reset Failed')
            } else if (res.status === 200) {
              alert("Password Reset Successfully")
              logToServer('info','password Reset Successfully')
              navigate("/login")
              sessionStorage.removeItem('email');
            //   setTimeout(() => {
            //     
            // }, 2000);
            }
          }
          catch (e) {
            toast.error("Unexpected error occured. Please try again");
            logToServer("error",`${e}`)
          }
        }else{
          toast.warn("Password should contain at least one uppercase letter, one lowercase letter, one digit, and be at least 8 characters long");
        }
       
      } else {
        toast.error("Password and Comfirm Password don't match");
      }

    } else {
      toast.error("Please enter Password and Comfirm Password");
    }
  }



    return (
      <>
      <div className="login-layout">
        <div className="form-div">
          <form className="forml">
            <img
              src={`${files}vgtlogo.png`}
              alt="logo"
              width="240px"
              height="70px"
              style={{ margin: '0px 0px 25px 25px' }}
            />
            
            {!enter && (
              <>
                <input
                  type="email"
                  className="input"
                  value={member_email}
                  onChange={(e) => setUserEmail(e.target.value)}
                  placeholder="Enter email"
                  required
                />
                <br />
                <button
                  type="submit"
                  className="login"
                  id="login-btn"
                  onClick={(e) => sendOTP(e)}
                >
                  Send OTP
                </button>
                <br />
                <button
                  type="button"
                  className="login"
                  id="back-btn"
                  onClick={() => navigate("/login")}
                >
                  Back
                </button>
              </>
            )}

            {enter  && !reset && (
              <>
                <OTP setReset={setReset} />
                <br />
                <button
                  type="button"
                  className="login"
                  id="back-btn"
                  onClick={() => SetEnter(false)}
                >
                  Back
                </button>
              </>
            )}

            {reset && (
              <>
                <input
                  type="password"
                  className="input"
                  value={pass}
                  onChange={(e) => setPass(e.target.value)}
                  placeholder="New password"
                  required
                />
                <input
                  type="password"
                  className="input"
                  value={cPass}
                  onChange={(e) => setCpass(e.target.value)}
                  placeholder="Confirm password"
                  required
                />
                <br />
                <button
                  type="submit"
                  className="login"
                  id="reset-btn"
                  onClick={(e) => resetPass(e)}
                >
                  Reset Password
                </button>
                <br />
                <button
                  type="button"
                  className="login"
                  id="back-btn"
                  onClick={() => {
                    SetEnter(false);
                    setReset(false)
                  }}
                >
                  Back
                </button>
              </>
            )}
          </form>
        </div>
      </div>
      <ToastContainer position="bottom-right" theme="colored" draggable={false} />
    </>
    )
  }

  export default Forget