import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import "./ManageOrg.css";
import HOST from "../host";
import files from "../static";
import { GlobalContext } from "../../App";
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import OrganizationForm from "../Authforms/OrgForm";
import { logToServer } from "../logger";

export default function OrganizationTable({ email }) {
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [organizationId, setOrganizationId] = useState([null]);
  const [name, setName] = useState("");
  const [number, setContactNumber] = useState("");
  const [website_name, setwebsite_name] = useState("");
  const [address, setaddress] = useState("");
  const [logo, setlogo] = useState("");
  const [email_address, setEmailAddress] = useState("");
  const [isUpdate, setIsUpdate] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [userPermissions, setUserPermissions] = useState([]);
  const [showOrgForm, setShowOrgForm] = useState(false);
  const { userInfo, getCsrfToken } = useContext(GlobalContext);

  useEffect(() => {
    fetchOrganizations(email);
  }, []);

  useEffect(() => {
    if (userInfo && userInfo.user_permissions) {
      setUserPermissions(userInfo.user_permissions);
    }
  }, [userInfo]);

  const fetchOrganizations = (email) => {
    fetch(`${HOST}/read-organization/${email}`,
      {
        credentials: 'include',
      })
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setOrganizations(data);
          logToServer('info',"fetching organizations successfully")
        } else {
          console.error("Error: Data is not an array");
        }
      })
      .catch((error) => {
        logToServer('error',`Error fetching organizations:${error}`)
      });
  };

  // Function to handle member deletion
  const handleDeleteOrg = async (event, organizationId) => {
    event.preventDefault();
    if (window.confirm("Are you sure you want to delete this organziation?")) {
      await fetch(`${HOST}/delete-organization/`, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          'X-CSRFToken': await getCsrfToken(),
        },
        body: JSON.stringify({ organization_id: organizationId }),
      })
        .then((response) => {
          if (response.ok) {
            setDeleteSuccess(true);
            logToServer('info','Organization deleted successfully')
            fetchOrganizations(email);
          } else {
            throw new Error("Failed to delete organization");
          }
        })
        .catch((error) => {
          logToServer('error',`Error deleting organization:${error}`)
        });
    }
  };


  const handleUpdateOrg = async (e) => {
    e.preventDefault();
  
    var formData = new FormData();
    formData.append('organization_id', organizationId);
    formData.append('name', name);
    formData.append('email_address', email_address);
    formData.append('website_name', website_name);
    formData.append('number', number);
    formData.append('address', address);
  
    if (logo instanceof File) {
      formData.append('logo', logo);
    }
  
    if (!email_address.trim()) {
      delete formData['email_address'];
    }
  
    if (!number.trim()) {
      delete formData['number'];
    }
  
    const formDataObject = {};
    formData.forEach((value, key) => {
      formDataObject[key] = value;
    });
  
    await fetch(`${HOST}/update-organization/`, {
      method: "POST",
      credentials: 'include',
      body: JSON.stringify(formDataObject),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-CSRFToken': await getCsrfToken(),
      },
    })
      .then((response) => {
        if (response.ok) {
          toast.success('Organization updated successfully')
          logToServer('info','Organization updated successfully')
          return response.json();
        } else {
          throw new Error("Failed to update organization");
        }
      })
      .then((data) => {
        console.log(data);
        if (data.message === 'Update successful') {
          setUpdateSuccess(true);
          fetchOrganizations();
          setName("");
          setContactNumber("");
          setEmailAddress("");
          setwebsite_name("");
          setaddress("");
          setlogo("");
          setIsUpdate(null);
          fetchOrganizations(formData['email_address']);
        } else {
          throw new Error("Failed to update organization");
        }
      })
      .catch((error) => {
        // Log error to server
        logToServer("error",`Failed to update organization${error}`);
        toast.error("Failed to update organization");
      });
  };
  


  const handleEdit = (organizationId, organizationData) => {
    setOrganizationId(organizationId);
    setName(organizationData.name);
    setContactNumber(organizationData.number);
    setEmailAddress(organizationData.email_address);
    setwebsite_name(organizationData.website_name);
    setaddress(organizationData.address);
    setlogo(organizationData.logo);
    setIsUpdate(organizationId);
    setModalIsOpen(true)
  };

  const toggleOrgForm = () =>{
    setShowOrgForm(true);

  }



  return (
    <>
      {!showOrgForm ? (
        <div className="table-container" style={{ maxHeight: 'calc(100vh - 300px)' }}>
          {(userInfo.is_superuser || userInfo.user_permissions.includes("add_organization")) && (
            <button className="btn-add"  onClick={() => toggleOrgForm()}><i className="fa fa-plus"> </i>  add organization</button>
          )}

          <div className="table-container" style={{ maxHeight: 'calc(100vh - 300px)', overflow: 'hidden', overflowY: 'scroll', boxShadow: '1px 5px 10px 8px #bbbbbb' }}>
            <table>
              {organizations.map((organization) => (
                <>
                  <tr>
                    <td className="border-0">

                      <div style={{ width: "200px", borderRadius: "10%" }}>
                        <img
                          className="logo-img"
                          src={`http://localhost:8000${organization.logo}`}
                          alt="Logo"
                          style={{ width: "200px", borderRadius: "10%" }}
                        />
                      </div>

                    </td>
                  </tr>
                  <tr>
                    <th className="border-0 th">
                      {!isUpdate && (userInfo.is_superuser || userPermissions.includes('change_organization')) && (
                        <button
                          type='button' className="btn-update" data-toggle="modal" data-target=".bd-example-modal-lg"
                          onClick={() => handleEdit(organization.id, organization)}
                        >
                          <i className="fa fa-pencil"></i>
                        </button>
                      )}

                      {(userPermissions.includes('delete_organization') || userInfo.is_superuser) && (
                        <button
                          className="btn-delete"
                          onClick={(e) => handleDeleteOrg(e, organization.id)}
                        >
                          <i className="fa fa-trash"></i>
                        </button>
                      )}
                    </th>
                  </tr>
                  <tr key={organization.id}>
                    <th className="th">Name</th>
                    <td>
                      {
                        organization.name
                      }
                    </td>
                  </tr>
                  <tr>
                    <th className="th">Email</th>
                    <td>
                      {
                        organization.email_address
                      }
                    </td>
                  </tr>
                  <tr>
                    <th className="th">Website Name</th>
                    <td>

                      <Link
                        to={organization.website_name}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: 'rgb(67, 144, 175)' }}
                      >
                        {organization.website_name}
                      </Link>

                    </td>
                  </tr>
                  <tr>
                    <th className="th">Contact No.</th>
                    <td>
                      {
                        organization.number
                      }
                    </td>
                  </tr>
                  <tr >
                    <th className="th">Address</th>
                    <td>
                      {
                        organization.address
                      }
                    </td>
                  </tr>

                </>
              ))}
            </table>
          </div>
          {isUpdate && (
            <>
              <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                contentLabel="Example Modal"
                className='col-lg-3 col-sm-2 custom-modal'
              >
                <h4>
                  Update organization
                </h4>


                <form className="col-lg-10   col-md-6 col-sm-10 mt-2">
                  <div class="form-group">
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="mt-2 form-control"
                      placeholder="Enter name"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      value={email_address}
                      onChange={(e) => setEmailAddress(e.target.value)}
                      className="mt-2 form-control"
                      placeholder="Enter email"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      value={website_name}
                      onChange={(e) => setwebsite_name(e.target.value)}
                      className="mt-2 form-control"
                      placeholder="Enter url"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      value={number}
                      maxLength={13}
                      onChange={(e) => setContactNumber(e.target.value)}
                      className="mt-2 form-control"
                      placeholder="Start with country code"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      value={address}
                      onChange={(e) => setaddress(e.target.value)}
                      className="mt-2 form-control"
                      placeholder="Enter Address"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="file"
                      name="logo"
                      accept="image/png, image/jpeg"
                      onChange={(e) => setlogo(e.target.files[0])}
                      className="mt-2 form-control"
                    />
                  </div>
                </form>
                <button className='mt-4 btn-add' style={{ marginLeft: '47%' }} onClick={handleUpdateOrg}>Update</button>
                <button type="submit" className='mt-3 cancel' onClick={() => {
                  setModalIsOpen(false);
                  setIsUpdate(false);
                }}>Close</button>
              </Modal>

            </>
          )}
        </div>
      ) : (
        <OrganizationForm setShowOrgForm={setShowOrgForm} />
      )}
      <ToastContainer position="bottom-right" theme="colored" draggable={false} />
    </>
  );
}