import * as L from 'leaflet';
import { createDeckInstance, updateDeckView } from './deck-utils';
import { GeoJsonLayer,TextLayer, } from '@deck.gl/layers';
import {TileLayer} from '@deck.gl/geo-layers';
import  {Feature, Geometry} from 'geojson';
import {center,centroid,bbox} from "@turf/turf"
import eventEmitter from '../eventEmitter';
const building = [
  "#00FF00", "#12FF00", "#24FF00", "#36FF00", "#48FF00",
    "#5AFF00", "#6CFF00", "#7EFF00", "#90FF00", "#A2FF00",
    "#B4FF00", "#C6FF00", "#D8FF00", "#EAFF00", "#FCFF00",
    "#FF1200", "#FF2400",  "#FF6C00","#FF4800", "#FF5A00",
    "#FF3600",
];
const green = [
  "#C8FFC8", "#BEFFBE", "#B4FFB4", "#AAFFAA", "#A0FFA0",
  "#96FF96", "#8CFF8C", "#82FF82", "#78FF78", "#6EFF6E",
  "#64FF64", "#5AFF5A", "#50FF50", "#46FF46", "#3CFF3C",
  "#32FF32", "#28FF28", "#1EFF1E", "#14FF14", "#0AFF0A",
  "#00FF00"
];

/** @typedef {import('@deck.gl/core').Deck} Deck */
/** @typedef {import('@deck.gl/core/lib/deck').DeckProps} DeckProps */

export default class VectorCanvas extends L.Layer {
  /** @type {HTMLElement | undefined} */
  _container = undefined;

  /** @type {Deck | undefined} */
  _deck = undefined;

  /** @type {boolean | undefined} */
  _animate = undefined;

  /**
   * @param {DeckProps} props
   */
  constructor(props) {
    super(props);
    this.layers = [];
    this.layers_visible={}
    this.layers_color={}
    this.layers_type={}
    this.layers_type_id={}
    this.layers_min_max={}
    this.layers_last_grad={}
    this.layers_filter={}
    this.layers_bounds={}
    this.angle=0;
    this.bearing=0;
    eventEmitter.on('getProps', this.getProp_2.bind(this));
    eventEmitter.on("change-attr",this.LayerShowAtribute.bind(this))
    eventEmitter.on("text-color-change",this.textColorChange.bind(this))
    eventEmitter.on("add-random",this.AddRandom.bind(this))
    eventEmitter.on("reset-layer",this.resetLayerColor.bind(this))
    eventEmitter.on("color-range",this.colorRange.bind(this))
  }

  /**
   * @returns {this}
   */

  getProp_2(data) {
    if (data && data.name) {
      const result = this.getProps(data.name,true);
      if (data.resolve && result.length > 0) {
        data.resolve(result);
      } else {
        console.error('Resolve function is missing in data');
      }
    } else {
      console.error('Data or data.name is missing');
    }
  }
  
  hexToRgbA(hex) {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      return [(c >> 16) & 255, (c >> 8) & 255, c & 255, 255];

    }
    throw new Error('Bad Hex');
  }
  changeAngle(pos) {
    this.angle+=pos
    updateDeckView(this._deck, this._map,this.angle,this.bearing);
      
    
  }
  changeBearing(pos) {
    this.bearing+=pos
    updateDeckView(this._deck, this._map,this.angle,this.bearing);
      
    
  }
  threeD(val){
    try{
      if(val){
        this._deck.props.layers.filter(layer => layer.id === "THREED")[0].visible=true
        var mapPane = document.querySelector('.leaflet-map-pane');
        if(mapPane){
          mapPane.style.display = `block`;  
          }
      }else{
        this.angle=0
        this.bearing=0
        this._deck.props.layers.filter(layer => layer.id === "THREED")[0].visible=false
         var mapPane = document.querySelector('.leaflet-map-pane');
         if(mapPane){
         mapPane.style.display = `block`;  
         }
        
      }
      this._update()
    }catch(e){
      
    }
   
  }
  addLayerUrl(name,url){
    console.log(url)
    let layer = new GeoJsonLayer({
      id: name,
      data: url,
      getLineWidth: 0.1,
      getPointRadius: 1,
      pointType: 'circle+text',
      getLineColor:[0,0,0],
      lineWidthMinPixels: 2,
      pointRadiusMinPixels: 5,
      pickable: true,
      filled: false,
      visible: true,
      onDataLoad:(data)=>{
          let box=bbox(data)
          let bounds = [
            [box[1], box[0]], 
            [box[3], box[2]]  
          ];
          this.layers_bounds[name]=[box[1],box[0],box[3],box[2]]
          this._map.flyToBounds(bounds)
        
      },
      
    })
    layer.visible=true
    this._deck.setProps({ layers: [...this._deck.props.layers, layer] })
    this.layers.push(name)
    this.layers_visible[name]=name
    this.layers_type_id[name]="polygon";
  }
  addLayerGeo(name,data){
    let layer = new GeoJsonLayer({
      id: name,
      data: data,
      getLineWidth: 0.1,
      getPointRadius: 1,
      pointType: 'circle+text',
      getLineColor:[0,0,0,255],
      lineWidthMinPixels: 2,
      pointRadiusMinPixels: 5,
      pickable: true,
      filled: false,
      visible: true,    
    })
    layer.visible=true
    this._deck.setProps({ layers: [...this._deck.props.layers, layer] })
    this.layers.push(name)
    this.layers_visible[name]=name
    this.layers_type_id[name]="polygon";
    let box=bbox(data)
          let bounds = [
            [box[1], box[0]], 
            [box[3], box[2]]  
          ];
          this.layers_bounds[name]=[box[1],box[0],box[3],box[2]]
          this._map.flyToBounds(bounds)
  }
  async addLayer( name, id,color=null, fill=null,upload=false,bounds=null) {
    color=color?color:"#000000"
    let url=upload?`https://portal.vasundharaa.in/geoserver/wfs?service=WFS&version=1.1.0&request=GetFeature&typename=useruploads:${name}&srsname=EPSG:4326&outputFormat=application/json`:`https://portal.vasundharaa.in/geoserver/wfs?service=WFS&version=1.1.0&request=GetFeature&typename=VGT:${name}&srsname=EPSG:4326&outputFormat=application/json`
    let layer;
    let line;
    let fillColor;
    console.log(this._deck.props)
    if (this.layers.includes(name)) {
      this._deck.props.layers.filter(layer => layer.id === name)[0].visible=true
      let first=null
      try{
        first=this._deck.props.layers.filter(layer => layer.id === `${name}-text`)[0]
      }catch(e){
  
      }
      if(first){
        this._deck.props.layers.filter(layer => layer.id === `${name}-text`)[0].visible=true
      }
    }
    else {
      if (color[1].length === 7) {
        line = this.hexToRgbA(color[1])
        fillColor = this.hexToRgbA(color[0])
      } else {
        line = this.hexToRgbA(color)
        fillColor = this.hexToRgbA(color)
      }
      if (fill) {
        fillColor[3] = 153;
      } else {
        fillColor[3] = 0;
      }
      let type=null;
      this.fire("fetching")
      layer = new GeoJsonLayer({
        id: name,
        data: url,
        getLineWidth: 0.1,
        getPointRadius: 1,
        pointType: 'circle+text',
        // extruded:true,
        // getElevation: f => 1000,
        // wireframe:true,
        lineWidthMinPixels: 2,
        pointRadiusMinPixels: 5,
        pickable: true,
        filled: true,
        getLineColor: line,
        getFillColor: (object) => {

          if (object.geometry.type === "Point" || object.geometry.type === "MultiPoint") {
           this.layers_type[id]="point"
           this.layers_type_id[name]="point"
            try {
              return this.hexToRgbA(color[0])
            } catch (e) {
              return this.hexToRgbA(color)
            }

          } else {
            this.layers_type[id]="polygon"
            this.layers_type_id[name]="polygon"
            if(name==="pune_new_green_cover" || name==="pune_new_building_cover" || name==="pune_new_building_density" || name==="pune_new_green_cover"){
              
              let toUse=null;
              toUse= name.includes("green")?green : building
              console.log(toUse)
              let index = Math.round(object.properties.Density / 0.05);
              return this.hexToRgbA(toUse[index])
            }
            return fillColor;
          }
        },
        visible: true,
        onDataLoad:(data)=>{
          if(upload){
            this.layers_bounds[name]=[bounds[0][0],bounds[0][1],bounds[1][0],bounds[1][1]]
          }else{
            let box=bbox(data)
            this.layers_bounds[name]=[box[1],box[0],box[3],box[2]]
          }
          // console.log(layer.props.data)
          if(upload){
            console.log(bounds)
        this._map.flyToBounds(bounds)
      }
          this.fire("fetched")
        },
        
        
      })

      layer.visible = true
      this._deck.setProps({ layers: [...this._deck.props.layers, layer] })
      this.layers.push(name)
      this.layers_color[name]=color
     
    }

    this.layers_visible[id]=name
    this._update();
    this.fire("layerchange");
    
  }

  async addCsvMarker( id, geojsonMarkers = null) {
    let layer;

    let name = 'Smart AI output'
    if (this.layers.includes(name)) {
        this._deck.props.layers.filter(layer => layer.id === id)[0].visible = true;
    } else {
        this.fire("fetching");

        if (geojsonMarkers) {
            layer = new GeoJsonLayer({
                id: id,
                data: geojsonMarkers,
                getLineWidth: 0.1,
                getPointRadius: 1,
                lineWidthMinPixels: 2,
                pointRadiusMinPixels: 5,
                pickable: true,
                visible: true,
                onDataLoad: (data) => {
                    this.fire("fetched");
                },
            });

            layer.visible = true;
            this._deck.setProps({ layers: [...this._deck.props.layers, layer] });
            this.layers.push(id);

            this.layers_visible[name] = id;
            let box = bbox(geojsonMarkers)
            this.layers_bounds[id]=[box[1],box[0],box[3],box[2]]
            this._update();
            this.fire("layerchange");

        } else {
            console.error('No geojsonMarkers provided.');
        }
    }
}
  
  addFilterLayer(data,name,color,query){
    let ids=name+query+Math.random().toString(36).substring(2, 5);
    let col=color?color:"#000000"
   let layer = new GeoJsonLayer({
      id: ids,
      data: data,
      getLineWidth: 0.1,
      getPointRadius: 1,
      lineWidthMinPixels: 2,
      pointRadiusMinPixels: 5,
      pickable: true,
      filled: true,
      getLineColor: this.hexToRgbA(col),
      getFillColor: [0,0,0,0],
      visible: true,
    })
    layer.visible = true;
    this._deck.setProps({ layers: [...this._deck.props.layers, layer] })
    if(name in this.layers_filter){
      this.layers_filter[name].push([ids,col])
    }else{
      this.layers_filter={...this.layers_filter,[name]:[[ids,col]]}
    }
    this._update();
  }
  getLayerId(name){
    return (this.layers_visible[name])
  }
  getLayerGeo(name){
     let data=this._deck.props.layers.filter(layer => layer.id === name)[0].props.data
     let box=bbox(data)
    return [data,[box[1],box[0],box[3],box[2]]]
  }
  LayerRename(prev,newName){
    if (Object.keys(this.layers_visible).includes(prev.split("#")[0])){
      const {[prev.split("#")[0]]:name,... rest}= this.layers_visible
    const newData= {[newName.split("#")[0]]:name,... rest}
    this.layers_visible=newData
    delete this.layers_visible[prev.split("#")[0]]
    }
    this._update();
    console.log(this.layers,this.layers_visible)
  }
  removeFilterLayer(id,name){
    this._deck.setProps({ layers: this._deck.props.layers.filter(layer => layer.id !== id) });
    this.layers_filter[name].splice(this.layers_filter[name].findIndex(ele => ele[0] === id), 1);
    this._update();
  }
  getFilterList(name){
    if(name in this.layers_filter){
      return this.layers_filter[name]
    }else{
      return []
    }
  }
  removeLayerFromMap(name,id){
    let first=null
    try{
      first=this._deck.props.layers.filter(layer => layer.id === `${name}-text`)[0]
    }catch(e){

    }
    if(first){
      this._deck.setProps({ layers: this._deck.props.layers.filter(layer => layer.id !== `${name}-text`) });
    }
    this._deck.setProps({ layers: this._deck.props.layers.filter(layer => layer.id !== name) });
    delete this.layers_visible[id];
    delete this.layers_color[id];
    delete this.layers_type[id];
    delete this.layers_type_id[name]
    this._update();
    this.fire("layerchange");
  }
  removeLayer(name,id) {
    if (this.layers.includes(name)) {
      try{
        delete this.layers_visible[id];
      }catch(e){

      }
      let first=null
      try{
        first=this._deck.props.layers.filter(layer => layer.id === `${name}-text`)[0]
      }catch(e){
  
      }
      if(first){
        this._deck.props.layers.filter(layer => layer.id === `${name}-text`)[0].visible=false;
      }
      this._deck.props.layers.filter(layer => layer.id === name)[0].visible=false;
      this._update();
      this.fire("layerchange");
    }
  }
  colorRange(data) {
    try {
      const { name,atr,range,palette ,checked } = data;
      console.log(range)
      const layer = this._deck.props.layers.find(layer => layer.id === name);
      let minValue=null
      let maxValue=null
      const length=layer.props.data.features.length
      if(atr!==this.layers_last_grad[name]){
        const features = layer.props.data.features;
        minValue = Infinity;
        maxValue = -Infinity;
        features.forEach(feature => {
            const value = feature.properties[atr];
            if (value < minValue) minValue = value;
            if (value > maxValue) maxValue = value;
        });
        this.layers_min_max[name]=[minValue,maxValue]
        this.layers_last_grad[name]=atr
      }else{
        minValue=this.layers_min_max[name][0]
        maxValue=this.layers_min_max[name][1]
      }
      let numColors = range !== "norm" ? Math.ceil((maxValue - minValue) / range) : length;
let norm = range === "norm" ? Math.ceil((maxValue - minValue) / length) : range; // Adjust norm for 'norm' range calculation

const generateColors = (numColors, palette) => {
    const colors = [];
    for (let i = 0; i < numColors; i++) {
        const t = numColors > 1 ? i / (numColors - 1) : 0; // Handle single color case
        if (palette === 'Green') {
            colors.push([204 * (1 - t), 255 * (1 - t), 204 * (1 - t)]);
        } else if (palette === 'Red') {
            colors.push([255 * (1 - t), 204 * (1 - t), 204 * (1 - t)]);
        } else if (palette === 'Red-to-Green') {
            colors.push([
                255 * (1 - t) + 0 * t,
                0 * (1 - t) + 255 * t,
                0 * (1 - t) + 0 * t,
            ]);
        } else if (palette === 'Green-to-Red') {
            colors.push([
                0 * (1 - t) + 255 * t,
                255 * (1 - t) + 0 * t,
                0 * (1 - t) + 0 * t,
            ]);
        }
    }
    return colors;
};

const colors = generateColors(numColors, palette);

const cloned_ = layer.clone({
    getFillColor: (object) => {
        const value = object.properties[atr];
        const colorIndex = range !== "norm" ? Math.floor((value - minValue) / range) : Math.floor((value - minValue) / norm);
        return colors[colorIndex % colors.length];
    },
});

      this._deck.setProps({ layers: this._deck.props.layers.filter(layer => layer.id !== name) });
      setTimeout(() => {
          cloned_.visible = checked ? true : false;
          this._deck.setProps({ layers: [cloned_, ...this._deck.props.layers] });
          this._update();
      }, 10);
  } catch (e) {
      console.log(e);
  }
}
  textColorChange(data){
    const {name,color,checked}=data
    this.LayerColorChange(`${name}-text`,color,checked,true)
  }
  LayerColorChange(name,color,checked,text=false){
    try{
      const cloned_=!text?this.layers_type_id[name]==="polygon"?this._deck.props.layers.filter(layer => layer.id === name)[0].clone({getLineColor:this.hexToRgbA(color)}):this._deck.props.layers.filter(layer => layer.id === name)[0].clone({getFillColor:this.hexToRgbA(color)}):this._deck.props.layers.filter(layer => layer.id === name)[0].clone({getColor:this.hexToRgbA(color)})
    this._deck.setProps({ layers: this._deck.props.layers.filter(layer => layer.id !== name) });

    setTimeout(() => {
      cloned_.visible=checked?true:false
      this._deck.setProps({ layers: [...this._deck.props.layers, cloned_] })
      this._update();
      this.layers_color[name]=color
    }, 1);
    
    }
    catch(e){
      console.log(e)
    }
  }
  AddRandom(data){
    try{
      
      const {name,checked}=data
     const cloned_=this._deck.props.layers.filter(layer => layer.id === name)[0].clone({getFillColor: (object) => {
      const color = [Math.random() * 255, Math.random() * 255, Math.random() * 255];
      return color;
    
  },})
    this._deck.setProps({ layers: this._deck.props.layers.filter(layer => layer.id !== name) });
    setTimeout(() => {
          cloned_.visible=checked?true:false
          this._deck.setProps({ layers: [cloned_,...this._deck.props.layers] })
          this._update();
        }, 10);
    }catch(e){
      console.log(e)
    }
    
  }
  resetLayerColor(data){
    try{
      
      const {name,checked}=data
     const cloned_=this.layers_type_id[name]==="point"?this._deck.props.layers.filter(layer => layer.id === name)[0].clone({getFillColor: (object) => {
      return this.hexToRgbA(this.layers_color[name])
    
  },}):this._deck.props.layers.filter(layer => layer.id === name)[0].clone({getFillColor: (feature) => {
    return [0,0,0,0]
   
}})
    this._deck.setProps({ layers: this._deck.props.layers.filter(layer => layer.id !== name) });
    setTimeout(() => {
          cloned_.visible=checked?true:false
          this._deck.setProps({ layers: [...this._deck.props.layers, cloned_] })
          this._update();
        }, 10);
    }catch(e){
      console.log(e)
    }
    

  }
  LayerOpacityChange(name,opacity,checked,text=false){
    let color=!text?this._deck.props.layers.filter(layer => layer.id === name)[0].props.getLineColor:this._deck.props.layers.filter(layer => layer.id === name)[0].props.getColor
    const cloned_=!text?this._deck.props.layers.filter(layer => layer.id === name)[0].clone({getLineColor:[color[0],color[1],color[2],255*opacity]}):this._deck.props.layers.filter(layer => layer.id === name)[0].clone({getColor:[color[0],color[1],color[2],255*opacity]})
    this._deck.setProps({ layers: this._deck.props.layers.filter(layer => layer.id !== name) });
    cloned_.visible=checked?true:false
    this._deck.setProps({ layers: [...this._deck.props.layers, cloned_] })
    this._update();
    let first=null
    try{
      first=this._deck.props.layers.filter(layer => layer.id === `${name}-text`)[0]
    }catch(e){

    }
    if(first){
      this.LayerOpacityChange(`${name}-text`,opacity,checked,true)
    }
  }
   getPositions(feature) {
    const { geometry } = feature;
    if (geometry.type === 'Point') {
      return geometry.coordinates;
    } else if (geometry.type === 'LineString') {
      // Example: Calculate midpoint for LineString
      const coords = geometry.coordinates;
      const midPoint = [
        (coords[0][0] + coords[1][0]) / 2,
        (coords[0][1] + coords[1][1]) / 2,
      ];
      return midPoint;
    } else if (geometry.type === 'Polygon' || geometry.type === 'MultiPolygon' ) {
      return centroid(feature).geometry.coordinates;
    }
  }

  LayerShowAtribute(data){
    const {name,atr,checked}=data
      let textLayer=null
      let first=null
      try{
        
        const cloned_=this._deck.props.layers.filter(layer => layer.id === name)[0]
        try{
          first=this._deck.props.layers.filter(layer => layer.id === `${name}-text`)[0]
        }catch(e){
    
        }
        if(first){
          textLayer=first.clone({getText: (d) => {
            if(atr!=="No Attribute"){
             
              return String(d.properties[atr])
            }
            return null
          },})

          this._deck.setProps({ layers: this._deck.props.layers.filter(layer => layer!==first) });
        }else{
          textLayer = new TextLayer({
            id: `${cloned_.id}-text`,
            data: cloned_.props.data.features,
            getPosition: this.getPositions,
            getText: (d) => {
              if(atr!=="No Attribute"){
               
                return String(d.properties[atr])
              }
              return null
            },
            getColor: [0, 0, 0,255],
            background:true,
            getBackgroundColor:[255,255,255,100],
            getSize: 15,
            maxWidth:10,
            outlineWidth:5,
            outlineColor:[255,255,255,255],
            getTextAnchor: 'middle',
            getAlignmentBaseline: 'bottom',
            fontFamily: 'Roboto, sans-serif',
            sdf:true,
            characterSet: 'auto',
            pickable:false
          });
        }
        
        
        setTimeout(() => {
          textLayer.visible=checked?true:false
          this._deck.setProps({ layers: [...this._deck.props.layers, textLayer] })
          this._update();
        }, 10);
      
      }catch(e){
        console.log(e)
      }
  
  }
  getLayers() {
    return this.layers_visible ? Object.keys(this.layers_visible) : []
  }
  getLayersColor(){
    let res=[]
    Object.keys(this.layers_visible).forEach((key) => {
      try{
        res.push({"name":key,"color":this.layers_color[this.layers_visible[key]],"type":this.layers_type[key]})
      }catch(e){
        console.log(e)
      }
      
    }
  )
  return res
  }
  getProps(name, type = false) {
    if (name in this.layers_visible) {
      const features = this._deck.props.layers.filter(layer => layer.id === this.layers_visible[name])[0].state.features;
  
      for (const feat of Object.keys(features)) {
        if (features[feat].length) {
          const properties = features[feat][0].__source.object.properties;
  
          if (type) {
            
            return Object.keys(properties).map(key => ({
              key: key,
              type: typeof properties[key]
            }));
          } else {
          
            return Object.keys(properties);
          }
        }
      }
    } else {
      return [];
    }
  }
  async ValidateQuery(name,query,color,box=null){
    let queries=query;
    if(box){
      const bboxCondition = `bbox(the_geom,${box[0]},${box[1]},${box[2]},${box[3]})`;
      if(queries!==""){
        queries = bboxCondition + " AND " + queries;
      }else{
        queries=bboxCondition
      }
    }
    this.fire("loading");
    let url=this.layers_visible[name].includes("upload")?`https://portal.vasundharaa.in/geoserver/useruploads/ows?service=WFS&version=1.0.0&request=GetFeature&typename=useruploads:${this.layers_visible[name]}&srsname=EPSG:4326&CQL_FILTER=${queries}&outputFormat=application/json`:`https://portal.vasundharaa.in/geoserver/VGT/ows?service=WFS&version=1.0.0&request=GetFeature&typename=VGT:${this.layers_visible[name]}&srsname=EPSG:4326&CQL_FILTER=${queries}&outputFormat=application/json`;
    const response = await fetch(url);
    if (response.ok) {
      try {
        const data= await response.json()
        if(data.totalFeatures){
          this.addFilterLayer(data,name,color,query)
          return([1,data.totalFeatures])
        }else{
          return([1,0])
        }
      
      }catch(e){
        return([0])
      }
    }
     else {
      throw new Error('Network response was not ok');
    }
    
  }
  clear(){
    this._deck.setProps({ layers: [] });
    this._update();
  }
  isBoundingBoxInView(mapBounds, bbox) {
   
    const [minLng, minLat, maxLng, maxLat] = bbox;
  
    // Create a LatLngBounds object from the bounding box
    const layerBounds = L.latLngBounds(
      L.latLng(minLng, minLat),
      L.latLng(maxLng, maxLat)
    );
    // Check if the layer bounds intersect with the map bounds
    return mapBounds.intersects(layerBounds);
  }
  onAdd() {
    this._container = L.DomUtil.create('div');
    this._container.className = 'leaflet-layer deck-custom';
    this._container.id="deckgl-custom"
    this._container.style.pointerEvents = "none";
    if (this._zoomAnimated) {
      L.DomUtil.addClass(this._container, 'leaflet-zoom-animated');
    }
    this._map.on('click', async (event) => {
      const viewportPoint = this._map.latLngToContainerPoint(event.latlng);
      let layids=[]
      let mapbounds=this._map.getBounds();
      
      Object.keys(this.layers_bounds).forEach((ele)=>{
      
        if (Object.values(this.layers_visible).includes(ele) && this.isBoundingBoxInView(mapbounds, this.layers_bounds[ele])){
          layids.push(ele)
        }
      })

          const pickedInfo = this.pickObject({ x: viewportPoint.x, y: viewportPoint.y,radius:15,layerIds:layids});
      if (pickedInfo) {
        let content = '';
        let value="";
        let status=null
        try{
        status = "Normalised"  in pickedInfo.object.properties;

        }catch(e){
          
        }
        
        for (const key in pickedInfo.object.properties) {

          if(key === "Density" && status) {
            continue;
          }
          if (key==="latitude_longitude_geometry"){
            continue;
          }
          value = pickedInfo.object.properties[key];
          if (value) {
            value = String(value);
            if (value.includes("http://") || value.includes("https://")) {
                value = `<a href="${value}" target="_blank">${value}</a>`;
            }
            content += `${key}: ${value}<br>`;
        }
          }
          
        L.popup(pickedInfo.coordinate.reverse(), { content: content })
          .openOn(this._map);
          
      }
      
      
    });
    this.getPane().appendChild(this._container);
    this._deck = createDeckInstance(this._map, this._container, this._deck);
    this._update();

    return this;
  }

  /**
   * @param {L.Map} _map
   * @returns {this}
   */
  onRemove(_map) {
    L.DomUtil.remove(this._container);
    this._container = undefined;

    this._deck.finalize();
    this._deck = undefined;

    return this;
  }

  /**
   * @returns {Object}
   */
  getEvents() {
    const events = {
      viewreset: this._reset,
      movestart: this._onMoveStart,
      moveend: this._onMoveEnd,
      zoomstart: this._onZoomStart,
      zoom: this._onZoom,
      zoomend: this._onZoomEnd,
    };
    if (this._zoomAnimated) {
      events.zoomanim = this._onAnimZoom;
    }
    return events;
  }

  /**
   * @param {DeckProps} props
   * @returns {void}
   */


  /**
   * @param {any} params
   * @returns {any}
   */
  pickObject(params) {
    return this._deck && this._deck.pickObject(params); 
  }

  /**
   * @param {any} params
   * @returns {any}
   */
  pickMultipleObjects(params) {
    return this._deck && this._deck.pickMultipleObjects(params);
  }

  /**
   * @param {any} params
   * @returns {any}
   */
  pickObjects(params) {
    return this._deck && this._deck.pickObjects(params);
  }

  /**
   * @returns {void}
   */
  _update() {
    if (this._map._animatingZoom) {
      return;
    }

    const size = this._map.getSize();
    this._container.style.width = `${size.x}px`;
    this._container.style.height = `${size.y}px`;

    // invert map position
    const offset = this._map._getMapPanePos().multiplyBy(-1);
    L.DomUtil.setPosition(this._container, offset);

    updateDeckView(this._deck, this._map,this.angle,this.bearing);
  }

  /**
   * @returns {void}
   */
  _pauseAnimation() {
    if (this._deck.props._animate) {
      this._animate = this._deck.props._animate;
      this._deck.setProps({ _animate: false });
    }
  }

  /**
   * @returns {void}
   */
  _unpauseAnimation() {
    if (this._animate) {
      this._deck.setProps({ _animate: this._animate });
      this._animate = undefined;
    }
  }

  /**
   * @returns {void}
   */
  _reset() {
    this._updateTransform(this._map.getCenter(), this._map.getZoom());
    this._update();
  }

  /**
   * @returns {void}
   */
  _onMoveStart() {

    this._pauseAnimation();

  }

  /**
   * @returns {void}
   */
  _onMoveEnd() {
    this._update();
    this._unpauseAnimation();
  }

  /**
   * @returns {void}
   */
  _onZoomStart() {
    this._pauseAnimation();
  }

  /**
   * @param {L.ZoomAnimEvent} event
   * @returns {void}
   */
  _onAnimZoom(event) {
    this._updateTransform(event.center, event.zoom);
  }

  /**
   * @returns {void}
   */
  _onZoom() {
    this._update();
    this._updateTransform(this._map.getCenter(), this._map.getZoom());
  }

  /**
   * @returns {void}
   */
  _onZoomEnd() {
    this._unpauseAnimation();
  }

  /**
   * see https://stackoverflow.com/a/67107000/1823988
   * see L.Renderer._updateTransform https://github.com/Leaflet/Leaflet/blob/master/src/layer/vector/Renderer.js#L90-L105
   * @param {L.LatLng} center
   * @param {number} zoom
   */
  _updateTransform(center, zoom) {
    const scale = this._map.getZoomScale(zoom, this._map.getZoom());
    const position = L.DomUtil.getPosition(this._container);
    const viewHalf = this._map.getSize().multiplyBy(0.5);
    const currentCenterPoint = this._map.project(this._map.getCenter(), zoom);
    const destCenterPoint = this._map.project(center, zoom);
    const centerOffset = destCenterPoint.subtract(currentCenterPoint);
    const topLeftOffset = viewHalf.multiplyBy(-scale).add(position).add(viewHalf).subtract(centerOffset);

    if (L.Browser.any3d) {
      L.DomUtil.setTransform(this._container, topLeftOffset, scale);
    } else {
      L.DomUtil.setPosition(this._container, topLeftOffset);
    }
  }
}

 //IMP Used for getting properties while downloading data

        // async function fetchData() {
        //   let url ="/geoserver/wfs?service=WFS&version=1.1.0&request=GetFeature&typename=VGT:Malegaon_Manhole&srsname=EPSG:4326&outputFormat=application/json"
        //   try {
        //     const response = await fetch(url);
        //     const reader = response.body.getReader();
        
        //     let buffer = '';

        //     while (true) {
        //       const { done, value } = await reader.read();
        
        //       if (done) {
        //         // Log or use the complete buffer when the file is done
        //         console.log('Complete Buffer:', buffer);
        //         break;
        //       }
        
        //       const chunk = new TextDecoder().decode(value);
        //       buffer += chunk;
        
        //       const regex = /"properties":\s*{([^}]*)}/g;
        //       let match;
        
        //       while ((match = regex.exec(chunk)) !== null) {
        //         const propertiesContent = match[1];
        //         console.log('Properties Content:', propertiesContent);
        //       }
        
        //       // Uncomment the line below if you want to log the buffer at each chunk
        //       // console.log('Buffer:', buffer);
        //     }
        //   } catch (error) {
        //     console.error('Error fetching data:', error);
        //   }
        // }
        
        // fetchData();