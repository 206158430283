import React, { useState, useContext, useEffect, useRef } from "react";
import { GlobalContext } from "../../../App";
import HOST from "../../host";

function GeoTool() {
  const [show, setShow] = useState(false);
  const [layer, setLayer] = useState("");
  const [type, setType] = useState("");
  const [projects, setProjects] = useState([]);
  const [pro, setPro] = useState("");
  const { vis, userInfo } = useContext(GlobalContext);
  const toolvisRef = useRef(null);


  const AddLayer = () => {
    const layerName = prompt("Enter Layer Name:");
    if (!layerName) {
      alert("Layer name is required.");
      return;
    }

    if (!type || !pro) {
      alert("Please select both a type and a project.");
      return;
    }

    // Construct the data to send

    // Send the request to the server
    fetch(`${HOST}/geo-to-pro`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: { name: layerName, id: pro, type: type, layer_name:layer }, }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to add layer");
        }
        alert("Layer Added Successfully")
        setType(null);
        setPro(null);
        setLayer("")
        
      })
      .catch((error) => {
        console.error("Error adding layer:", error);
        alert("Failed to add layer.");
      });
  };

  const fetchProjects = () => {
    fetch(`${HOST}/fetch-projects-all`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch projects");
        }
        return response.json();
      })
      .then((data) => {
        setProjects(data.projects);
      })
      .catch((error) => {
        console.error("Error fetching projects:", error);
      });
  };

  useEffect(() => {
    if (userInfo && userInfo.is_superuser && !projects.length) {
      fetchProjects();
    }
  }, [userInfo,projects]);

  return (
    <div
      style={{ position: vis ? "absolute" : "relative" }}
      className="toolscont"
    >
      <button
        title=""
        className="btn btn-dark text-white"
        id="roadroute"
        onClick={() => setShow((prevShow) => !prevShow)}
        style={{
          zIndex: "1000",
          fontSize: "15px",
          padding: "2px 2px",
          width: "40px",
          height: "40px",
          borderRadius: "50%",
          border: "none",
      }}
      >
        <i>Geo</i>
      </button>
      {show && (
        <div
          ref={toolvisRef}
          className="toolsvis"
          id="toolvis"
          style={{ display: "flex", flexDirection: "column", width: "200px" }}
        >
          <input
            placeholder="Enter Layer Name"
            value={layer}
            onChange={(e) => setLayer(e.target.value)}
          />
          <div>
            <input
              type="radio"
              value="raster"
              checked={type === "raster"}
              onChange={(e) => setType("raster")}
              id="raster"
            />
            <label htmlFor="raster">Raster</label>
            <input
              type="radio"
              value="vector"
              checked={type === "vector"}
              onChange={(e) => setType("vector")}
              id="vector"
            />
            <label htmlFor="vector">Vector</label>
          </div>
          <select onChange={(e) => setPro(e.target.value)} value={pro}>
            <option value="">Select Project</option>
            {projects.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
          {pro && layer.trim() && type && <button onClick={()=>AddLayer()}>Add Layer</button>}
        </div>
      )}
    </div>
  );
}

export default GeoTool;
