import React, { useState, useEffect, useContext, useRef } from "react";
import HOST from "../host";
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import { GlobalContext } from "../../App";
import PermissionsTable from "./Permissions";
import CreateUser from "../Authforms/AddUser";
import { logToServer } from "../logger";
import './Manageuser.css';

export default function UserTable({ email }) {
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [memberId, setUserId] = useState([]);
  const [name, setName] = useState("");
  const [member_email, setUserEmail] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [members, setUsers] = useState([]);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [userPermissions, setUserPermissions] = useState([]);
  const { userInfo, getCsrfToken } = useContext(GlobalContext);
  const toastShownRef = useRef(false);
  const [showPermissions, setShowPermissions] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [currentPage, setCurrentPage] = useState(1);
  const [membersPerPage] = useState(10); // Adjust as needed

  useEffect(() => {
    fetchUsers(email);
  }, [email]);

  useEffect(() => {
    if (userInfo && userInfo.user_permissions) {
      setUserPermissions(userInfo.user_permissions);
    }
  }, [userInfo]);

  const fetchUsers = (email) => {
    if (email) {
      fetch(`${HOST}/read-member/${email}`, {
        credentials: 'include',
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.success === true) {
            setUsers(response.data);
            setFilteredMembers(response.data);
            logToServer('info', 'fetching member Successfully');
          } else {
            if (!toastShownRef.current && response.message === 'User does not have permission to perform this action.') {
              toast.error(response.message);
              toastShownRef.current = true;
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching members:", error);
          logToServer('error', `${error}`);
        });
    }
  };

  const handleDeleteUser = async (memberId) => {
    if (window.confirm("Are you sure you want to remove this member?")) {
      await fetch(`${HOST}/delete/`, {
        method: "POST",
        credentials: 'include',
        body: JSON.stringify({ member_id: memberId }),
        headers: {
          "Content-Type": "application/json",
          'X-CSRFToken': await getCsrfToken(),
        },
      })
        .then((response) => {
          if (response.ok) {
            setDeleteSuccess(true);
            toast.success('User deleted successfully');
            logToServer('info', 'Deleted user successfully');
            fetchUsers(email);
          } else {
            console.log(response.message);
            if (!toastShownRef.current && response.message === 'User does not have permission to perform this action.') {
              toast.error(response.message);
              toastShownRef.current = true;
            }
            throw new Error("Failed to delete user");
          }
        })
        .catch((error) => {
          logToServer('error', `${error}`);
        });
    }
  };

  const handleUpdateUser = async (event, memberId) => {
    event.preventDefault();
    const updatedUserData = {
      member_id: memberId,
      username: name,
      email: member_email,
    };

    await fetch(`${HOST}/update-member/`, {
      method: "POST",
      credentials: 'include',
      body: JSON.stringify(updatedUserData),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-CSRFToken': await getCsrfToken(), 
      },
    })
      .then((response) => {
        if (response.ok) {
          setUsers((prevUsers) =>
            prevUsers.map((user) => {
              if (user.id === memberId) {
                return {
                  ...user,
                  username: name,
                  email: member_email,
                };
              }
              return user;
            })
          );
          setFilteredMembers((prevUsers) =>
            prevUsers.map((user) => {
              if (user.id === memberId) {
                return {
                  ...user,
                  username: name,
                  email: member_email,
                };
              }
              return user;
            })
          );
          setUpdateSuccess(true);
          toast.success('User updated successfully');
          fetchUsers(email);
          setIsUpdate(false);
          logToServer('info', 'user update Successfully');
          return;
        } else {
          toast.error("Updated value already exists");
          throw new Error("Failed to update member");
        }
      })
      .catch((error) => {
        logToServer('error', `${error}`);
      });
  };

  const handleEdit = (memberId, memberData) => {
    setName(memberData.username);
    setUserEmail(memberData.email);
    setUserId(memberId);
    setIsUpdate(true);
    setModalIsOpen(true);
  };

  const togglePermissions = (memberId, memberName) => {
    console.log("Viewing permissions for:", memberId, memberName);
    setShowPermissions(true);
    setSelectedUserId(memberId);
  };

  const toggleCreateForm = () => {
    setShowCreateForm(!showCreateForm);
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    const filtered = members.filter(
      (member) =>
        member.username.toLowerCase().includes(query.toLowerCase()) ||
        member.email.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredMembers(filtered);
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  useEffect(() => {
    let sortedMembers = [...filteredMembers];
    if (sortConfig.key !== null) {
      sortedMembers.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    setFilteredMembers(sortedMembers);
  }, [sortConfig]);
  
  // Pagination logic
  const indexOfLastMember = currentPage * membersPerPage;
  const indexOfFirstMember = indexOfLastMember - membersPerPage;
  const currentMembers = filteredMembers.slice(indexOfFirstMember, indexOfLastMember);
  const totalPages = Math.ceil(filteredMembers.length / membersPerPage);

  const paginate = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > totalPages) return;
    setCurrentPage(pageNumber);
  };
  
  const getArrow = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? '▲' : '▼';
    }
    return '';
  };

  return (
    <>
      {!showPermissions ? (
        <div className="table-container" style={{ height: "80vh" }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              {(userPermissions.includes('add_user') || userInfo.is_admin) && (
                <button className="btn-add" onClick={() => toggleCreateForm()}>
                  <i className="fa fa-plus"></i>
                  Create User
                </button>
              )}
            </div>

            <div style={{ marginRight: '6px', marginBottom: '15px' }}>
              <input
                type="text"
                placeholder="Search Users..."
                value={searchQuery}
                onChange={handleSearch}
                className="search-input"
                style={{ marginLeft: '10px', padding: '5px', borderRadius: '3px', border: '1px solid #ccc' }}
              />
            </div>
          </div>
          <div style={{ height: '70vh', overflowY: 'scroll' }}>
            <table className="custom-table1" style={{ boxShadow: 'none' }}>
              <thead style={{ position: 'sticky', top: '0', zIndex: '1', backgroundColor: '#f5f5f5' }}>
                <tr>
                  <th onClick={() => handleSort('username')} style={{ cursor: 'pointer' }}>
                    Username {getArrow('username')}
                  </th>
                  <th onClick={() => handleSort('email')} style={{ cursor: 'pointer' }}>
                    Email {getArrow('email')}
                  </th>
                  <th>Permission</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredMembers.map((member) => (
                  <tr style={{ height: '75px' }} key={member.id}>
                    <td>{member.username}</td>
                    <td>{member.email}</td>
                    <td>
                      {(userPermissions.includes('change_user') || userInfo.is_admin) && member.id !== userInfo.id && !member.is_admin && (
                        <button className="permissions" style={{ backgroundColor: '#2c3e50' }} onClick={() => togglePermissions(member.id, member.name)}>View Permissions</button>
                      )}
                    </td>
                    <td style={{ display: 'flex', justifyContent: 'flex-start' }}>
                      <td style={{ width: '50px', padding: '0px' }}>
                        {(userPermissions.includes('change_user') || userInfo.is_admin) && !member.is_admin && member.id !== userInfo.id && (
                          <button type='button' className="btn-update" data-toggle="modal" data-target=".bd-example-modal-lg" onClick={() => handleEdit(member.id, member)}>
                            <i className="fa fa-pencil"></i>
                          </button>
                        )}
                      </td>
                      <td style={{ width: '50px', padding: '0px' }}>
                        {(userPermissions.includes('delete_user') || userInfo.is_admin) && member.id !== userInfo.id && !member.is_admin && (
                          <button className="btn-delete" onClick={() => handleDeleteUser(member.id)}>
                            <i className="fa fa-trash"></i>
                          </button>
                        )}
                      </td>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {isUpdate && (
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={() => setModalIsOpen(false)}
              contentLabel="Update User Modal"
              className='col-lg-2 col-sm-2 custom-modal'
              style={{ zIndex: '1' }}
            >
              <h4>Update User</h4>
              <form className="col-lg-10 col-md-6 col-sm-10 mt-5">
                <input
                  type="text"
                  value={name}
                  className="mt-3 form-control"
                  onChange={(e) => setName(e.target.value)}
                />
                <input
                  type="text"
                  value={member_email}
                  className="mt-3 form-control"
                  onChange={(e) => setUserEmail(e.target.value)}
                />
              </form>
              <button type="submit" className='mt-5 btn-add' style={{ marginLeft: '42%' }} onClick={(e) => handleUpdateUser(e, memberId)}>Update User</button>
              <button type="submit" className='mt-5 cancel' onClick={() => { setModalIsOpen(false); setIsUpdate(false); }}>Close</button>
              <p>{updateSuccess}</p>
            </Modal>
          )}

          {showCreateForm && (
            // <Modal
            //   isOpen={showCreateForm}
            //   onRequestClose={() => setShowCreateForm(false)}
            //   contentLabel="Create User Modal"
            //   className='col-lg-2 col-sm-2 custom-modal'
            //   style={{ zIndex: '1' }}
            // >
             
            // </Modal>
             <CreateUser setShowCreateForm={setShowCreateForm} />
          )}

          <div className="pagination">
            <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
              &lt;
            </button>
            {[...Array(totalPages)].map((_, index) => (
              <button
                key={index + 1}
                onClick={() => paginate(index + 1)}
                className={currentPage === index + 1 ? 'active' : ''}
              >
                {index + 1}
              </button>
            ))}
            <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>
              &gt;
            </button>
          </div>
        </div>
      ) : showPermissions && !showCreateForm && (
        <PermissionsTable memberId={selectedUserId} setShowPermissions={setShowPermissions} />
      )}
      <ToastContainer position="bottom-right" theme="colored" draggable={false} />
    </>
  );
}
