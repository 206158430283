import React from "react";

function Template({children}){

    return(
        <div className="container" >
            <div className="hiddenActionCont">
            {children}
            </div>
            </div>
    )

}

export default Template;