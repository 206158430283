import React,{useState,useContext,useEffect} from "react";
import { GlobalContext } from "../../../App";
import HOST from "../../host";


function Options({clip ,AddBound ,clipBox ,clipLayer ,selCont,selState, setClip,setClipLayer ,setBound ,setClipBox ,setCLayer ,setCont ,setState, setDis }){
    const [vectors, setVectors] = useState([]);
    const [country, setCounts] = useState([]);
    const [state, setStates] = useState([]);
    const [district, setDistricts] = useState([]);
    
    const {
        map,
        Canvas,
        
        
      } = useContext(GlobalContext);
    const handleClipBoxChange = (e) => {
        const isChecked = e.target.checked;
        setClipBox(isChecked);
        if (isChecked) {
          setClip(false);
          setClipLayer(false);
        }
      };
    
      const handleClipChange = (e) => {
        const isChecked = e.target.checked;
        setClip(isChecked);
        if (isChecked) {
          setClipBox(false);
          setClipLayer(false);
        }
      };
      const handleClipLayerChange = (e) => {
        const isChecked = e.target.checked;
        setClipLayer(isChecked);
        if (isChecked) {
          setVectors(Canvas.getLayers());
          setClipBox(false);
          setClip(false);
        }
      };
      function HandleCont(name) {
        if (name !== selCont) {
          setCont(name);
          setState("");
          setDis("");
          setStates([]);
          setDistricts([]);
          if (name !== "") {
           
              fetchList("state", name);
          }
        }
      }
      function HandleState(name) {
        if (name !== selState) {
          setState(name);
          setDis("");
          setDistricts([]);
          if (name !== "") {
           
              fetchList("dis", name);
            
    
          }
        }
      }
    
      async function fetchList(name, pay) {
        let url;
        if (name) {
          let payload = pay;
          url = new URL(`${HOST}/clip-list/${name}/${payload}`);
        } else {
          url = new URL(`${HOST}/clip-list`);
        }
    
        try {
        //   setloader(true);
          await fetch(url)
            .then((response) => response.json())
            .then((data) => {
              if (name === "state") {
                setStates(data.state);
              } else if (name === "dis") {
                setDistricts(data.district);
              } else {
                setCounts(data.country);
              }
            });
        //   setloader(false);
        } catch (error) {
          console.error("Error sending POST request:", error.message);
          alert("Unexpected Error occured Please try again");
        }
      }
      useEffect(() => {
        let timeoutId;
    
        const handleMoveEnd = () => {
          clearTimeout(timeoutId);
    
          timeoutId = setTimeout(() => {
            if (clip) {
              console.log("caleee")
              var latlng = map.getCenter();
              fetch(
                `https://nominatim.openstreetmap.org/reverse?lat=${latlng.lat}&lon=${latlng.lng}&format=json`
              )
                .then((response) => response.json())
                .then((data) => {
                  console.log(data)
                  setCont(data.address.country);
                  
                    fetchList("state", data.address.country)
                  
                })
                .catch((error) => console.error("Error:", error));
            }
          }, 200);
        };
        if (map) {
          map.on("move", handleMoveEnd);
        }
    
        if (clip) {
          handleMoveEnd()
        }
        return () => {
          clearTimeout(timeoutId);
          if (map) {
            map.off("move", handleMoveEnd);
          }
    
        };
      }, [map, clip]);
  useEffect(() => {
    if (clip) {
      setStates([]);
      setDistricts([]);
      setCont("");
      fetchList();
    }
  }, [clip]);
    return(
        <div>
        <details className="baseline"  >
        <summary style={{ fontSize: "12px" }}> Additional options </summary>
        <div className="baseline-cont" style={{ margin: "2px 0px 5px 5px" }}>
          <div className="opt-div">
            <input
              onChange={handleClipBoxChange}
              checked={clipBox}
              className="form-check-input check-map"
              type="checkbox"
            />
            <label>Clip by Box</label>
          </div>
          <div className="opt-div">
            <input
              onChange={handleClipChange}
              checked={clip}
              className="form-check-input check-map"
              type="checkbox"
            />
            <label>Clip by Region</label>
          </div>
          <div style={{ marginTop: clip ? "10px" : "0px" }}>
            {clip && (
              <>
                {country && country.length ? (
                  <select
                    className=" form-select custom-select"
                    onChange={(e) => HandleCont(e.target.value)}
                    value={selCont}
                    style={{ width: "150px", height: "25px", padding: "0px 10px", margin: "0px 0px 2px 5px", fontSize: '12px' }}
                  >
                    <option style={{ fontSize: "12px" }} value={""}>
                      Select Country
                    </option>

                    {country
                      .map((nme) => nme)
                      .sort()
                      .map((nme) => (
                        <option
                          style={{ textAlign: "left", fontSize: "12px" }}
                          key={nme}
                          value={nme}
                        >
                          {nme}
                        </option>
                      ))}
                  </select>
                ) : null}
                {state && state.length ? (
                  <select
                    className=" form-select custom-select"
                    onChange={(e) => HandleState(e.target.value)}
                    style={{ width: "150px", height: "25px", padding: "0px 10px", margin: "0px 0px 2px 5px", fontSize: '12px' }}
                  >
                    <option style={{ fontSize: "12px" }} value={""}>
                      Select State
                    </option>

                    {state
                      .map((nme) => nme)
                      .sort()
                      .map((nme) => (
                        <option
                          style={{ textAlign: "left", fontSize: "12px" }}
                          key={nme}
                          value={nme}
                        >
                          {nme}
                        </option>
                      ))}
                  </select>
                ) : null}
                {district && district.length ? (
                  <select
                    className=" form-select custom-select"
                    onChange={(e) => setDis(e.target.value)}
                    style={{ width: "150px", height: "25px", padding: "0px 10px", margin: "0px 0px 2px 5px", fontSize: '12px' }}
                  >
                    <option style={{ fontSize: "12px" }} value={""}>
                      Select District
                    </option>

                    {district
                      .map((nme) => nme)
                      .sort()
                      .map((nme) => (
                        <option
                          style={{ textAlign: "left", fontSize: "12px" }}
                          key={nme}
                          value={nme}
                        >
                          {nme}
                        </option>
                      ))}
                  </select>
                ) : null}

               
              </>
            )}
          </div>
          <div className="opt-div">
            <input
              onChange={handleClipLayerChange}
              checked={clipLayer}
              className="form-check-input check-map"
              type="checkbox"
            />
            <label>Clip by Layer</label>
          </div>
          <div style={{ marginTop: clipLayer ? "10px" : "0px" }}>
            {clipLayer && (
              <>
                {vectors ? (
                  <select
                    className=" form-select custom-select"
                    onChange={(e) => setCLayer(e.target.value)}
                    style={{ width: "150px", height: "25px", padding: "0px 10px", margin: "0px 0px 2px 5px", fontSize: '12px' }}
                  >
                    <option style={{ fontSize: "12px" }} value={""}>
                      Select Layer
                    </option>

                    {vectors.map((nme) => (
                      <option
                        style={{ textAlign: "left", fontSize: "12px" }}
                        key={nme}
                        value={nme}
                      >
                        {nme}
                      </option>
                    ))}
                  </select>
                ) : null}
              </>
            )}
          </div>
          
        </div>
      </details>
      </div>
    )


}


export default Options