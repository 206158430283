import React, { useContext } from "react";
import HOST from "../host";
import { GlobalContext } from "../../App";
import { useNavigate } from "react-router-dom";

function Payment() {
    const { userInfo } = useContext(GlobalContext);
    const navigate = useNavigate(); // Initialize useNavigate

    async function makePayment() {
        let amount = prompt("Please enter Amount");
        if (amount) {
            try {
                const response = await fetch(`${HOST}/payment-url/${amount}/${userInfo.id}`, {
                    method: 'POST',
                });
                const data = await response.json();
                
                if (data.url) {
                    console.log(data.url);
                    window.location.href = data.url; // Redirect the user to the payment URL
                }
            } catch (error) {
                console.error('Error sending message:', error);
            }
        } else {
            alert("Please enter an Amount");
        }
    }

    return (
        <>
            <button onClick={() => makePayment()}>Make Payment</button>
        </>
    );
}

export default Payment;
