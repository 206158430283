import React, { useState, useEffect, useContext } from "react";
import HOST from "../host";
import ShowOldConversation from "./ShowOldConversation";
import "./viewsurvey.css";
import { ToastContainer, toast } from 'react-toastify';
import { GlobalContext } from "../../App";
import { logToServer } from '../logger'
export default function ManageConversation({ id }) {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [conversations, setConversations] = useState([]);
    const { userInfo } = useContext(GlobalContext);
    const [idFetch, setId] = useState(null);
    const [showOldChat, setshowOldChat] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    useEffect(() => {
        fetchConversation(id);
    }, [id]);
    const fetchConversation = async (id) => {
        try {
            const response = await fetch(`${HOST}/manage-conversation/${id}`, {
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`,
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch conversation');
            }
            const data = await response.json();
            logToServer('info', 'Fetching conversation successfully');
            const conversationsWithFormattedDate = data.conversations.map((conversation, index) => ({
                ...conversation,
                serial: index + 1,
                created_at: conversation.created_at.split(' ')[0] // Extracting only the date part
            }));
            setConversations(conversationsWithFormattedDate);
        } catch (error) {
            logToServer('error', `Error fetching conversation: ${error}`);
        }
    };
    const deleteConversation = async (chatId) => {
        if (window.confirm("Are you sure you want to delete this Conversation?")) {
            try {
                const response = await fetch(`${HOST}/delete-conversation/${id}/${chatId}`);
                if (response.ok) {
                    logToServer('info', 'Conversation deleted successfully');
                    fetchConversation(id);
                } else {
                    throw new Error("Failed to delete Conversation");
                }
            } catch (error) {
                console.error("Error deleting Conversation:", error);
                logToServer('error', `Error deleting Conversation: ${error}`);
            }
        }
    };
    const sortBy = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };
    useEffect(() => {
        if (sortConfig.key !== null) {
            const sortedConversations = [...conversations].sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
            setConversations(sortedConversations);
        }
    }, [sortConfig]);
    const handleView = (userId, userInfo) => {
        setshowOldChat(true);
        setId(userId);
    };
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                event.target.closest(".chatbot-window") === null &&
                event.target.closest(".chat-btn") === null
            ) {
                setshowOldChat(false);
            }
        };
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);
    const getSortIcon = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'ascending' ? '▲' : '▼';
        }
        return null;
    };
    return (
        <>
            <div className="table-container" style={{ height: '70vh', overflowY: 'scroll', marginTop: '50px' }}>
                <table className="custom-table1">
                    <thead style={{ position: 'sticky', top: '0', zIndex: '1', backgroundColor: '#F5F5F5' }}>
                        <tr style={{ borderBottom: '1px solid gray' }}>
                            <th onClick={() => sortBy('username')}>
                                Username {getSortIcon('username')}
                            </th>
                            {/* <th onClick={() => sortBy('serial')}>
                                Chat Id {getSortIcon('serial')}
                            </th> */}
                            <th onClick={() => sortBy('created_at')}>
                                Chat Created At {getSortIcon('created_at')}
                            </th>
                            <th onClick={() => sortBy('total_messages')}>
                                Total Messages {getSortIcon('total_messages')}
                            </th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {conversations.map((conversation) => (
                            <tr key={conversation.chat_id}>
                                <td>{userInfo.username}</td>
                                {/* <td>{conversation.serial}</td> */}
                                <td>{conversation.created_at}</td>
                                <td>{conversation.total_messages}</td>
                                <td>
                                    <button type='button' className="btn-update chat-btn" data-toggle="modal" data-target=".bd-example-modal-lg"
                                        onClick={() => handleView(conversation.chat_id, userInfo)}>
                                        <i className="fa fa-eye"></i>
                                    </button>
                                    <button className="btn-delete" onClick={() => deleteConversation(conversation.chat_id)}>
                                        <i className="fa fa-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {showOldChat && (
                    <div className="chatbot-window" style={{ position: 'absolute', zIndex: '1000', top: '50%', left: '50%', backgroundColor: 'white', maxWidth: '650px', marginBottom: '3%', boxShadow: '1px 5px 10px 8px #BBBBBB', transform: 'translate(-50%, -50%)' }}>
                        <ShowOldConversation idFetch={idFetch} />
                        <button type="submit" className='cancel' style={{ marginLeft: '80%' }} onClick={() => { setshowOldChat(false) }}>Close</button>
                    </div>
                )}
                <ToastContainer position="bottom-right" theme="colored" draggable={false} />
            </div>
        </>
    )
}