import React from 'react';

const RefundAndCancellationPolicy = () => {
  return (
    <div className="policy-container">
      <h1>Refund and Cancellation Policy</h1>
      <p>
        This refund and cancellation policy outlines how you can cancel or seek a refund for a product or service
        that you have purchased through our Platform. By using our services or placing an order, you agree to the
        terms of this Refund and Cancellation Policy.
      </p>
	 <h2>1. Cancellations</h2>
      <p>
        You may request a cancellation within 7 days of placing the order. Cancellation requests may not be honored if the order has been forwarded to the seller/merchant and shipping has commenced, or if the product is out for delivery. In such cases, you can refuse the product at the time of delivery.
      </p>

      <h2>2. Defective Products</h2>
      <p>
        If you receive a defective product, please report it to our customer service team within 7 days of receipt.
      </p>

      <h2>3. Products Not as Expected</h2>
      <p>
        If the product you received does not meet your expectations or match the description, notify our customer service team within 7 days of receiving it. Our team will review your complaint and make an appropriate decision.
      </p>

      <h2>4. Refund Processing</h2>
      <p>
        If your refund request is approved, it will be processed within 10 days.
      </p>

      <h2>5. Returns</h2>
      <p>
        Contact us within 30 days of delivery to request a return. Items must be unused, in the same condition as received, and in the original packaging. Partial refunds may be granted for items not in their original condition, damaged, or missing parts not due to our error, or returned more than 30 days after delivery.
      </p>
          </div>
  );
};

export default RefundAndCancellationPolicy;
