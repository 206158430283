import React, { useState, createContext, useContext, useEffect } from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Actions from "./Actions/Actions";
import SidebarTabs from "./sidebarTabs";
import Free from "../projects/Free";
import CProject from "../Authforms/createProject";
import Survey from "../projects/Survey";
import { GlobalContext } from "../../App";
import files from "../static";
import "./map.css"
import { useNavigate } from "react-router-dom";
import Navigate from "../navigate";
import { logToServer } from "../logger";

export const SideBarContext = createContext();

function Sidebar() {
  const {
    tools,
    setTools,
    userInfo,
    userProjects,
    organizationProjects
  } = useContext(GlobalContext)
  const [showloader, setloader] = useState(false)
  const [showPloader, setPloader] = useState(false)
  const navigate = useNavigate();
  const contextValue = {
    setloader,
    setPloader,
  }

  useEffect(() => {
    console.log(window.location.pathname)
    if (!tools) {
      sidebarOpen();
      logToServer("info", "Sidebar component mounted"); // Log when the component mounts
    }
    // Uncomment this if you need to redirect when `userInfo` is not available
    // if(!userInfo){
    //   navigate(`/login`); 
    // }
  }, [userInfo, tools]);

  function sidebarOpen() {
    document.getElementById("mySidebar").style.display = "flex";
    document.getElementById("openNav").style.display = "none";
    setTools(false);
    logToServer("info", "Sidebar opened"); // Log when the sidebar is opened
  }

  function sidebarClose() {
    document.getElementById("mySidebar").style.display = "none";
    document.getElementById("openNav").style.display = "block";
    setTools(true);
    logToServer("info", "Sidebar closed"); // Log when the sidebar is closed
  }

  return (
    <div>
      <div
        className="bg-dark custom-sidebar"
        id="mySidebar"
      >
        <div style={{ display: 'flex' }}>
          <i onClick={sidebarClose} style={{ textAlign: 'center', flex: '2', color: 'white', marginTop: '10px' }} className="fa-solid fa-chevron-left"></i>
          <img
            className="logo"
            alt='this is logo'
            src={`${process.env.PUBLIC_URL}/${files}vgtlogo.png`}
            style={{ width: "85%", height: "100%", margin: "-17px 0px 10px 5px", flex: '9' }}
          />
        </div>

        <div className="sidebar-content">
          {showloader && (
            <div style={{ position: 'relative', marginLeft: '85%', marginBottom: '5%' }}>
              <div className="lds-dual-ring text-center">
                <i className="fa-solid fa-globe"></i>
                {/* <img alt='this is earth' src={`${process.env.PUBLIC_URL}/${files}earth.png`}/> */}
              </div>
            </div>
          )}

          <SideBarContext.Provider value={contextValue}>
            <Routes>
            
              <Route
                path="/*" 
                element={
                  userInfo ? (
                    <Actions />
                  ) : (<Navigate to="/" />)
                }
              />
              
              <Route
                path="/project/*"
                element={
                  userInfo ? (
                    <SidebarTabs />
                  ) : (<Navigate to="/" />)
                }
              />
              <Route
                path="/punecarbon"
                element={
                  <Free />
                }
              />
            </Routes>
          </SideBarContext.Provider>
        </div>
      </div>

      <button
        id="openNav"
        className="btn"
        style={{
          height: "40px",
          width: "40px",
          zIndex: "900",
          position: "absolute",
          top: "10px",
          left: "10px",
          borderRadius: "100%",
          border: "none",
          backgroundColor: "#2596be",
          color: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
        onClick={sidebarOpen}
      >
        &#9776;
      </button>
    </div>
  );
}

export default Sidebar;
