import React, { useEffect, useState, useContext, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Markdown from 'react-markdown';
import files from '../static';
import HOST from "../host";
import '../Pages/ManageOrg.css';
import '../Pages/ManageOrg.css';
import { GlobalContext } from '../../App';
import 'react-datepicker/dist/react-datepicker.css';
import { bbox, feature } from "@turf/turf";
import L from "leaflet";
import Papa from 'papaparse';
import CsvComp from './cvsComp';
// import ReactModal from 'react-modal';
import { helpers, area, convertArea } from "@turf/turf";
import Loading from './loading';


export default function Chatbot({ id, setShowChatbot }) {
    const [chatHistory, setChatHistory] = useState([]);
    const [promptMessage, setPromptMessage] = useState("");
    const [responseText, setResponseText] = useState("");
    const [geminiResponse, setgeminiResponse] = useState("");
    const [previousChatId, setPreviousChatId] = useState("");
    const { userInfo, getCsrfToken, lastRect,
        drawnItems, map, layerControls, Fstep, setFstep, CsDate, setCSdate, selTab, chatSmart, setSmart } = useContext(GlobalContext);
    const [eDate, setEdate] = useState("2024-01-31")
    const [districts, SetDist] = useState([])
    const [dis, SetDis] = useState(null)
    const [taluqa, setTalq] = useState([])
    const [tal, setTal] = useState(null)
    const lastMessageRef = useRef(null);
    const [mess, setMess] = useState(null)
    const Expro = "I want to calculate forest fire area";
    const Expro1 = "I want to get the green cover change"
    const [type, setType] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [csvData, setCsvData] = useState([]);
    const [csvDataTable, setCsvDataTable] = useState(false);
    const location = useLocation();
    const [mode,setMode]=useState(1);
    const [isLoading, setIsLoading] = useState(false); // State for loading indicator

    const dataRef = useRef([]);


    function CreateNDVI(data) {
        let layer;
        layer = L.tileLayer(data.url_1[0], { maxZoom: 20, zIndex: 1005 })
        layerControls.addOverlay(layer, `Raster  ${CsDate} `, false, false, false, false, false, data.url_1[1])
        layer.addTo(map)
        layer = L.tileLayer(data.url_2[0], { maxZoom: 20, zIndex: 1005 })
        layerControls.addOverlay(layer, `Raster  ${eDate} `, false, false, false, false, false, data.url_2[1])
        layer.addTo(map)
        layer = L.geoJSON(data.geo_1[0], { style: { fill: false, color: "#000000", fillOpacity: 0.5 } })
        layerControls.addOverlay(layer, `Green Cover ${CsDate}`, false, false, false, false, false, data.geo_1[1])
        layer = L.geoJSON(data.geo_2[0], { style: { fill: false, color: "#000000", fillOpacity: 0.5 } })
        layerControls.addOverlay(layer, `Green Cover ${eDate}`, false, false, false, false, false, data.geo_2[1])
        try {
            let total_pos = area(data.change_1[0]) / 10 ** 6
            let total_neg = area(data.change_2[0]) / 10 ** 6

            layer = L.geoJSON(data.change_1[0], { style: { fill: true, color: "#00FF00", } })
            layerControls.addOverlay(layer, "Positive Change", false, false, false, false, false, data.change_1[1])
            layer = L.geoJSON(data.change_2[0], { style: { fill: true, color: "#FF0000", } })
            layerControls.addOverlay(layer, "Negative Change", false, false, false, false, false, data.change_2[1])
            sendMessage(`postive-${total_pos.toFixed(2)}-negative-${total_neg.toFixed(2)}`);
        } catch (e) {

        }

        setMess("The layers has been added")
        setFstep(0)
    }
    function CreateFire(data) {
        console.log(data)
        let layer;
        let total = 0;


        function calculateAndFormatArea(feature) {
            let areaValue = area(feature);
            let convertedArea = convertArea(areaValue, "meters", "kilometers");
            return convertedArea;
        }

        let layers = L.geoJSON(JSON.parse(data.geo[0]), {
            onEachFeature: (feature, layer) => {
                let areaInKm = calculateAndFormatArea(feature);
                let formattedArea = areaInKm.toFixed(2);
                layer.bindPopup(`Area: ${formattedArea} square km`);
            },
            style: function (feature) {
                return { color: "#FF0000", fill: true, fillColor: "#000000" };
            }
        });
        total = calculateAndFormatArea(JSON.parse(data.geo[0]))
        layers.addTo(map)
        let bounds = layers.getBounds()
        layerControls.addOverlay(layers, "Fire Area Vector", true, bounds, false, false, false, data.geo[1])
        layers = L.tileLayer(data.norm[0], { zIndex: 1005, maxZoom: 20 })
        layers.addTo(map)
        layerControls.addOverlay(layers, "RGB", false, false, false, false, false, data.norm[1])
        layers = L.tileLayer(data.fire[0], { zIndex: 1005, maxZoom: 20 })
        layers.addTo(map)
        layerControls.addOverlay(layers, "SWIR", false, false, false, false, false, data.fire[1])
        setMess("The layers has been added")
        setFstep(0)
        sendMessage(`total_area-${total.toFixed(2)}`);
    }


    async function NDVI() {
        setMess("Please Wait")
        let data = {}
        data["Sdate"] = CsDate
        data["Edate"] = eDate
        if (Fstep === 3) {
            if (lastRect && drawnItems.hasLayer(lastRect)) {
                data["box"] = [JSON.stringify(
                    drawnItems.getLayer(lastRect).toGeoJSON()["geometry"]["coordinates"][0]
                )]

            } else {
                return
            }
        }
        if (Fstep === 4) {
            data["tal"] = [dis, tal]
        }
        if (window.location.pathname.startsWith("/project/")) {
            const projectId = location.pathname.split("/")[3];
            data["project"] = projectId;

        } else {
            data["project"] = "global";
        }
        data["memb"] = userInfo.id
        data["tab"] = selTab;
        console.log(data)
        try {
            await fetch(`${HOST}/ndvi-change`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ data }),
            })
                .then((response) => response.json())
                .then((data) => CreateNDVI(data));
        } catch (error) {
            alert("Unexpected Error occured Please try again");
            setFstep(0)
        }
    }
    async function FireVis() {
        setMess("Please Wait")
        let data = {}
        data["date"] = CsDate
        if (Fstep === 3) {
            if (lastRect && drawnItems.hasLayer(lastRect)) {
                data["box"] = [JSON.stringify(
                    drawnItems.getLayer(lastRect).toGeoJSON()["geometry"]["coordinates"][0]
                )]

            } else {
                return
            }
        }
        if (Fstep === 4) {
            data["tal"] = [dis, tal]
        }
        if (window.location.pathname.startsWith("/project/")) {
            const projectId = location.pathname.split("/")[3];
            data["project"] = projectId;

        } else {
            data["project"] = "global";
        }
        data["memb"] = userInfo.id
        data["tab"] = selTab;
        try {
            await fetch(`${HOST}/forest-fire`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ data }),
            })
                .then((response) => response.json())
                .then((data) => CreateFire(data));
        } catch (error) {
            alert("Unexpected Error occured Please try again");
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const previousChatData = await fetchPreviousChatId(userInfo.id);
                const chatId = previousChatData ? previousChatData.recent_chat_id : generateRandomString();
                setPreviousChatId(chatId);
                await fetchChatHistory(userInfo.id, chatId);
            } catch (error) {
                console.error("Error fetching chat data:", error);
            }
        };

        fetchData();
    }, [userInfo.id]);

    const fetchPreviousChatId = async (id) => {
        try {
            const response = await fetch(`${HOST}/get-previous-chat/${id}`);
            if (!response.ok) {
                throw new Error('Failed to fetch latest chat');
            }
            const data = await response.json();
            console.log('data', data);
            return data;
        } catch (error) {
            console.error("Error fetching latest chat:", error);
            return null;
        }
    };

    const fetchChatHistory = async (id, chatId) => {
        try {
            const response = await fetch(`${HOST}/get-chat-history/${id}/${chatId}`);
            if (!response.ok) {
                throw new Error('Failed to fetch chat history');
            }
            const data = await response.json();
            setChatHistory(data.his);
        } catch (error) {
            console.error("Error fetching chat history:", error);
            console.error("Error fetching chat history:", error);
        }
    };


    const generateRandomString = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const length = 16;
        let randomString = '';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            randomString += characters[randomIndex];
        }
        return randomString;
    }

    const resetChatConversation = async () => {
        const newChatId = generateRandomString();
        setPreviousChatId(newChatId);
        console.log(newChatId);
        await fetchChatHistory(userInfo.id, newChatId);
    };


    const handleTextareaChange = (event) => {
        const message = event.target.value;
        setPromptMessage(message);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            sendMessage();
        }
    };

    useEffect(() => {
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [chatHistory]);



    // function showTable(chatHistoryId, csvFilePaths) {
    //     const myDiv = document.getElementById(`chat_history_${chatHistoryId}`);
    //     let cont = getCsvRecords(csvFilePaths)
    //     console.log(cont)
    //     myDiv.innerHTML = cont;
    //     // setCsvDataTable(!csvDataTable);
    // }

    async function sendMessage(prompt = null) {

        let message = prompt ? prompt : promptMessage
        if (message && message !== "") {
            setIsLoading(true);
            if (!message.includes("total_area") && !message.includes("postive-")) {
                setChatHistory(prevChatHistory => [...prevChatHistory, { sender: 'user', text: message, time: new Date() }]);
            }
            setPromptMessage("");
            if ((message === Expro || message === Expro1) && Fstep === 0) {
                setPromptMessage("");
                setFstep(1);
                if (message === Expro) {
                    setType("fire")
                } else {
                    setType("ndvi")
                }
            }

            await fetch(`${HOST}/chat-history/${userInfo.id}/${previousChatId}/${mode}`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'X-CSRFToken': await getCsrfToken(),
                },
                body: JSON.stringify({ message: message, response: responseText }),
            })
                .then((response) => response.json())
                .then(data => {
                    console.log(data);

                    setPromptMessage('');
                    setPromptMessage('');
                    if (data.response) {
                        console.log(data.response);
                        setChatHistory(prevChatHistory => [
                            ...prevChatHistory,
                            { sender: 'bot', text: data.response, mode: data.mode, time: new Date(), paths: data.paths },
                        ]);


                    }
                })
                .catch(error => {
                    console.error('Error sending message:', error);
                    setIsLoading(false);
                });
            setIsLoading(false)
        }


    };


    useEffect(() => {
        if (dis) {
            setTal("")
            fetchList(dis)
        }

    }, [dis])




    async function fetchList(name) {

        let url;
        if (name) {
            url = new URL(`${HOST}/clip-list/tal/${name}`);
        } else {
            url = new URL(`${HOST}/clip-dis-list`);
        }

        try {
            await fetch(url)
                .then((response) => response.json())
                .then((data) => {
                    if (!name) {
                        SetDist(data.district)
                    } else {

                        setTalq(data.taluka)
                    }

                });
        } catch (error) {
            console.error("Error sending POST request:", error.message);
            alert("Unexpected Error occured Please try again");
        }
    }

    function HandleNext(e, i) {
        e.preventDefault()
        e.stopPropagation();
        setFstep(i);
        if (i === 4) {
            SetDist([])
            setTalq([])
            SetDis(null)
            setTal(null)

            fetchList();
        }
    }



    return (
        <>
            <div style={{ backgroundColor: 'white', width: '550px', marginBottom: '3%' }}>

                <div className="row d-flex justify-content-center border-0" style={{ maxHeight: '550px' }}>
                    <div className="col-xl-12" style={{ overflowY: 'hidden', position: 'relative', minHeight: "500px" }}>
                        <div className="card-header d-flex justify-content-between align-items-center m-0 p-3 text-white" style={{ backgroundColor: 'black', width: '100%', zIndex: '1', position: 'sticky', top: '0', maxWidth: '100%' }}>
                          <div className="d-flex align-items-center">
  <label htmlFor="smart" style={{ marginRight: "5px", fontSize: '16px', fontWeight: 'bold' }}>Mode</label>
  <select 
    id="smart" 
    className="form-select" 
    value={mode} 
    onChange={(e) => setMode(Number(e.target.value))} 
    style={{ width: '150px' }}
  >
    <option value={1}>Chatbot</option>
    <option value={2}>Perform Spatial Analysis</option>
    <option value={3}>Help & Support</option>
  </select>
</div>
                            <p className="mb-0 fw-bold flex-grow-1" style={{ marginLeft: "100px" }}>Live chat</p>
                            <i className="fas fa-times text-white" onClick={() => { setShowChatbot(false) }}></i>
                        </div>
                        <div className="card" id="chat1" style={{backgroundColor:"white", borderRadius: "15px", overflowY: 'scroll', borderStyle: "none", maxHeight: '450px' }}>
                            <div className="card-body" style={{ paddingTop: '60px', paddingBottom: '60px', overflowY: 'scroll', position: 'relative', zIndex: 0, maxHeight: '450px', minHeight: "450px", minWidth: '450px' }}>

                                {Array.isArray(chatHistory) && chatHistory.map((child, index) => (
                                    <div key={index} ref={index === chatHistory.length - 1 ? lastMessageRef : null} style={{ position: 'relative' }} className={`d-flex flex-row justify-content-${child.sender === 'user' ? 'end' : 'start'} mb-4`}>
                                        {child.sender === 'user' ? (
                                            <>
                                                {child.text !== "" ? (
                                                    <>
                                                        <div className="p-3 ms-3 position-relative" style={{ borderRadius: "15px", backgroundColor: "rgba(57, 192, 237, 0.2)", paddingBottom: '20px', minWidth: "60px" }}>
                                                            <p className="small" style={{ marginBottom: '20px' }}>{child.text}</p>
                                                            <div className="watermark" style={{ position: 'absolute', bottom: '5px', right: '5px', fontSize: '10px', color: 'rgba(0, 0, 0, 0.5)' }}>
                                                                {new Date(child.time).toLocaleString()}
                                                            </div>
                                                        </div>


                                                        <img src={`${process.env.PUBLIC_URL}/${files}userprofile.png`} alt="avatar 1" style={{ width: "45px", height: "100%", marginLeft: '2%' }} />
                                                    </>

                                                ) : null}
                                            </>
                                        ) : (
                                            <>
                                                <img src={`${process.env.PUBLIC_URL}/${files}chatbot.png`} alt="avatar 1" style={{ width: "45px", height: "100%" }} />
                                                <div className="p-3 border position-relative" style={{ borderRadius: "10px", backgroundColor: "#fbfbfb", maxWidth: '75%', overflowX: 'auto', overflowY: 'auto' }}>
                                                    {child.mode && (child.mode===1 || child.mode===3) && (
                                                        <p className="small mb-0" style={{ maxWidth: '100%', overflowWrap: 'break-word' }}>
                                                            <Markdown>{child.text}</Markdown>
                                                        </p>
                                                    )}

                                                    { child.mode && child.mode===2 && (
                                                        <>
                                                            {child.mode===2 && (
                                                                <>

                                                                    <Markdown>{child.text}</Markdown>



                                                                    {child.paths && child.paths.length ? <CsvComp index={index} url={child.paths} setIsLoading={setIsLoading} /> : (null)}

                                                                </>
                                                            )}

                                                            
                                                        </>
                                                    )}
						{child.mode && (
    <div className="watermark" style={{ position: 'absolute', bottom: '0px', right: '5px', fontSize: '10px', color: 'rgba(0, 0, 0, 0.5)' }}>
        {child.mode === 1 && "Chatbot Mode"}
        {child.mode === 2 && "Spatial Analysis Mode"}
        {child.mode === 3 && "Help & Support Mode"}
    </div>
)}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                ))}


                                {Fstep >= 1 && (
                                    <div className="d-flex flex-column align-items-start mb-4">
                                        <div className="p-3 me-3 border" style={{ borderRadius: "15px", backgroundColor: "#fbfbfb", maxWidth: '70%' }}>
                                            <div style={{ marginTop: "15px" }}>
                                                <label htmlFor="start" style={{ marginRight: "10px", color: "black", fontSize: '12px', display: "inline-block" }}>Date:</label>
                                                <input value={CsDate} onChange={(e) => setCSdate(e.target.value)} style={{ fontSize: '12px', padding: "3px 10px", borderRadius: "5px" }} type='date' id="start" name='start' />
                                            </div>
                                            {type === "ndvi" ? (
                                                <div style={{ marginTop: "15px" }}>
                                                    <label htmlFor="end" style={{ marginRight: "15px", color: "black", fontSize: '12px', display: "inline-block" }}>End Date:</label>
                                                    <input value={eDate} onChange={(e) => setEdate(e.target.value)} style={{ fontSize: '12px', padding: "3px 10px", borderRadius: "5px" }} type='date' id="end" name="end" />
                                                </div>
                                            ) : (null)}
                                            <button onClick={(e) => HandleNext(e, 2)} className="btn btn-dark mt-3">Next</button>
                                        </div>
                                    </div>
                                )}
                                {Fstep >= 2 && (
                                    <div className="d-flex flex-column align-items-start mb-4">
                                        <div className="p-3 me-3 border" style={{ borderRadius: "15px", backgroundColor: "#fbfbfb", maxWidth: '70%' }}>
                                            <div style={{ marginTop: "15px" }}>
                                                <button onClick={(e) => HandleNext(e, 3)}>Extent by Box</button>
                                            </div>


                                        </div>
                                    </div>
                                )}
                                {Fstep === 3 && (
                                    <div className="d-flex flex-column align-items-start mb-4">
                                        <div className="p-3 me-3 border" style={{ borderRadius: "15px", backgroundColor: "#fbfbfb", maxWidth: '70%' }}>
                                            <p>Please draw an Rectangle. Ignore if already drawn</p>
                                            <button onClick={() => type === "fire" ? FireVis() : NDVI()} className="btn btn-dark mt-3">Visualize</button>
                                        </div>
                                    </div>

                                )}
                                {Fstep === 4 && (
                                    <>
                                        <div className="d-flex flex-column align-items-start mb-4">
                                            <div className="p-3 me-3 border" style={{ borderRadius: "15px", backgroundColor: "#fbfbfb", maxWidth: '70%' }}>
                                                {districts && districts.length ? (
                                                    <>
                                                        <p>Please select a District</p>
                                                        <select
                                                            onChange={(e) => SetDis(e.target.value)}
                                                            className=" form-select custom-select"
                                                            style={{ width: "150px", height: "25px", padding: "0px 10px", margin: "0px 0px 2px 5px", fontSize: '12px' }}
                                                        >
                                                            <option style={{ fontSize: "12px" }} value={""}>
                                                                Select District
                                                            </option>

                                                            {districts
                                                                .map((nme) => nme)
                                                                .sort()
                                                                .map((nme) => (
                                                                    <option

                                                                        style={{ textAlign: "left", fontSize: "12px" }}
                                                                        key={nme}
                                                                        value={nme}
                                                                    >
                                                                        {nme}
                                                                    </option>
                                                                ))}
                                                        </select>
                                                    </>
                                                ) : null}
                                                {taluqa && taluqa.length ? (
                                                    <>
                                                        <p>Please select a Taluqa</p>
                                                        <select
                                                            onChange={(e) => setTal(e.target.value)}
                                                            className=" form-select custom-select"

                                                            style={{ width: "150px", height: "25px", padding: "0px 10px", margin: "0px 0px 2px 5px", fontSize: '12px' }}
                                                        >
                                                            <option style={{ fontSize: "12px" }} value={""}>
                                                                Select Taluqa
                                                            </option>

                                                            {taluqa
                                                                .map((nme) => nme)
                                                                .sort()
                                                                .map((nme) => (
                                                                    <option

                                                                        style={{ textAlign: "left", fontSize: "12px" }}
                                                                        key={nme}
                                                                        value={nme}
                                                                    >
                                                                        {nme}
                                                                    </option>
                                                                ))}
                                                        </select>
                                                    </>
                                                ) : null}
                                                {tal && tal !== "" && (
                                                    <button onClick={() => type === "fire" ? FireVis() : NDVI()} className="btn btn-dark mt-3">Visualize</button>

                                                )}
                                            </div>
                                        </div>
                                    </>
                                )}
                                {mess && (
                                    <>
                                        <div className="d-flex flex-column align-items-start mb-4">
                                            <div className="p-3 me-3 border" style={{ borderRadius: "15px", backgroundColor: "#fbfbfb", maxWidth: '70%' }}>
                                                <p>{mess}</p>
                                            </div>
                                        </div>
                                    </>
                                )}




                            </div>

                        </div>
                        <div data-mdb-input-init className="form-outline" style={{ position: 'sticky', bottom: '0', left: '0', width: '100%', zIndex: 1, background: '#fff', padding: '3px' }}>
                            <textarea
                                className="form-control"
                                id="textAreaExample"
                                rows="4"
                                style={{ height: '30px', maxHeight: '150px', width: 'calc(100% - 6px)', bottom: '0', left: '0',background:"white" }}
                                placeholder='Type your message....'
                                value={promptMessage}
                                onChange={handleTextareaChange}
                                disabled={Fstep >= 1}
                                onKeyDown={handleKeyDown}>
                            </textarea>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div>
                                    <button
                                        className='mt-3 fw-bold btn-add'
                                        style={{ backgroundColor: 'black', fontSize: '14px', width: 'fit-content', color: 'white', marginRight: '10px' }}
                                        onClick={() => sendMessage()}>
                                        Send
                                    </button>
                                    <button
                                        className='mt-3 fw-bold btn-add'
                                        style={{ backgroundColor: 'black', fontSize: '14px', width: 'fit-content', color: 'white' }}
                                        onClick={resetChatConversation}>
                                        New Conversation
                                    </button>
                                </div>
                                {isLoading && (
                                    <div style={{  opacity: '1', zIndex: '1000',position:"relative",marginRight:"50px" }}>
                                        <div className="lds-dual-ring">
                                            <i className="fa-solid fa-globe"></i>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}