import React, { useEffect } from "react";
import { useParams } from 'react-router-dom';


function Payment2(){
    const { transactionId } = useParams();
    useEffect(()=>{
        console.log(transactionId)
    },[transactionId])
    return(
        <>
    <p style={{color:"white"}}>Success</p>

        </>
    )


}

export default Payment2