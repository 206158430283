import React, { useEffect, useState } from 'react';
import HOST from '../host';
import "./log.css";

const Logs = () => {
  const [logs, setLogs] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [logsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: 'timestamp', direction: 'desc' });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch logs from API
  const fetchLogs = () => {
    setLoading(true);
    let url = `${HOST}/get_log?`;
    if (startDate) url += `start_date=${startDate}&`;
    if (endDate) url += `end_date=${endDate}`;

    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch logs');
        }
        return response.json();
      })
      .then(data => {
        setLogs(data.logs);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching logs:', error);
        setError('Failed to fetch logs. Please try again.');
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchLogs();
  }, [startDate, endDate]);

  const deleteLog = (id) => {
    fetch(`${HOST}/delete_log/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.ok) {
          setLogs(logs.filter(log => log.id !== id));
        } else {
          throw new Error('Failed to delete log');
        }
      })
      .catch(error => {
        console.error('Error deleting log:', error);
        setError('Failed to delete log. Please try again.');
      });
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };
  const sortedLogs = [...logs].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });
  const filteredLogs = sortedLogs.filter(log => {
    return (
      log.level.toLowerCase().includes(searchQuery.toLowerCase()) ||
      log.message.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });
  const indexOfLastLog = currentPage * logsPerPage;
  const indexOfFirstLog = indexOfLastLog - logsPerPage;
  const currentLogs = filteredLogs.slice(indexOfFirstLog, indexOfLastLog);
  const totalPages = Math.ceil(filteredLogs.length / logsPerPage);

  const paginate = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > totalPages) return;
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <div style={{ marginBottom: '10px' ,display:'flex',justifyContent:'space-between'}}>
        <div style={{marginLeft:'10px'}}>
          <label style={{ marginRight: '10px',color:'#2c3e50' }}>
          Start Date:
          <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} style={{ marginLeft: '5px', padding: '5px', borderRadius: '3px', border: '1px solid #ccc' }} />
        </label>
        <label style={{ marginRight: '10px' ,color:'#2c3e50' }}>
          End Date:
          <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} style={{ marginLeft: '5px', padding: '5px', borderRadius: '3px', border: '1px solid #ccc' }} />
        </label>
        <button onClick={fetchLogs} style={{ backgroundColor: '#2c3e50', color: '#fff', border: 'none', padding: '8px 15px', borderRadius: '3px', cursor: 'pointer' }}>Filter</button>
        </div>
       <div style={{marginRight:'10px'}}>
       <input
          type="text"
          placeholder="Search logs..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ marginLeft: '10px',padding: '5px', borderRadius: '3px', border: '1px solid #ccc' }}
        />
       </div>
        
      </div>

      {error && <div style={{ color: 'red', marginBottom: '10px' }}>{error}</div>}

      {loading ? (
        <div>Loading...</div>
      ) : (
        <div style={{ height: '80vh' }}>
          <div style={{ height: '75vh', overflowY: 'scroll' }}>
            <table className="custom-table1">
              <thead style={{ position: 'sticky', top: '0', zIndex: '1', backgroundColor: '#f5f5f5' }}>
                <tr>

                  <th onClick={() => handleSort('level')} style={{cursor:'pointer'}}>
                    Level {sortConfig.key === 'level' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                  </th>
                  <th onClick={() => handleSort('message')} style={{cursor:'pointer'}}>
                    Message {sortConfig.key === 'message' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                  </th>
                  <th onClick={() => handleSort('timestamp')} style={{cursor:'pointer'}}>
                    Timestamp {sortConfig.key === 'timestamp' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                  </th>
                  <th>Count</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {currentLogs.map((log, index) => (
                  <tr key={log.id}>
                    {/* <td>{indexOfFirstLog + index + 1}</td> */}
                    <td>{log.level}</td>
                    <td>{log.message}</td>
                    <td>{new Date(log.timestamp).toLocaleString()}</td>
                    <td>{log.count}</td>
                    <td>
                      <button className="btn-delete" onClick={() => deleteLog(log.id)}>
                        <i className="fa fa-trash"> </i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="pagination">
            <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>&lt;</button>
            {currentPage > 3 && <button onClick={() => paginate(1)}>1</button>}
            {currentPage > 4 && <span>...</span>}
            {currentPage > 2 && <button onClick={() => paginate(currentPage - 2)}>{currentPage - 2}</button>}
            {currentPage > 1 && <button onClick={() => paginate(currentPage - 1)}>{currentPage - 1}</button>}
            <button className="current-page">{currentPage}</button>
            {currentPage < totalPages && <button onClick={() => paginate(currentPage + 1)}>{currentPage + 1}</button>}
            {currentPage < totalPages - 1 && <button onClick={() => paginate(currentPage + 2)}>{currentPage + 2}</button>}
            {currentPage < totalPages - 3 && <span>...</span>}
            {currentPage < totalPages - 2 && <button onClick={() => paginate(totalPages)}>{totalPages}</button>}
            <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>&gt;</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Logs;