import React, { useState, useContext, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Segment from "./segment"
import Sat from "./satellite";
import Maxar from "./maxar";
import OD from "./od";
import NDVI from "./ndvi";
import Ship from "./ship";
import Template from "../projectTemplate";
import Process from "./process";
import Repo from "../../projects/MhWaterRepo";
import Building from "../../projects/building";
import Change from "./Change";
import InfoWindow from "./InfoWindow";
import P from './crown.png';
import { GlobalContext } from "../../../App";
// import SurveyForm from "../../Pages/SurveyForm";
function Actions({ selectedDataset }) {
  const { userInfo,
    setPrevMeta, } = useContext(GlobalContext);
  const { selTab, setTab } = useContext(GlobalContext);
  const location = useLocation();
  // const [showInfo, setShowInfo] = useState(false);
  // const infoRef = useRef(null);

  function paymentPrompt() {
    alert("This feature is locked. Please contact support.dharaatch@gmail.com for more information")
  }
  useEffect(() => {
    const handleAddedToPro = () => {
      setPrevMeta({});
    };
    document.addEventListener("added-to-pro", handleAddedToPro);
    return () => {
      document.removeEventListener("added-to-pro", handleAddedToPro);
    };
  }, [])
  // const dataInfo = () => {
  //   console.log('clicked i')
  //   setShowInfo(true);
  // }

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (infoRef.current && !infoRef.current.contains(event.target)) {
  //       setShowInfo(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);


  return (
    <>
      <div
        className="actions"
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          marginTop: '-10px',
        }}
      >
        <div style={{display:'flex', flexDirection:'row', padding:'5px 0px'}}>
          <h4 style={{ flex:'10', color: "#FAF8D4", fontSize: '14px' }}>Select An Action</h4>
         
        </div>
        <div className="select" tabIndex="1">
          <input
            className="selectopt"
            name="test"
            type="radio"
            id="opt1"
            checked={selTab === "opt1"}
            onChange={() => setTab("opt1")}

          />
          <label htmlFor="opt1" className="option" style={{ color: "#FAF8D4", fontSize: '12px', marginTop: "0px" }}>
            Open Source Satellite Datasets
          </label>
          <input
            className="selectopt"
            name="test"
            type="radio"
            id="opt2"
            checked={selTab === "opt2"}
            onChange={() => setTab("opt2")}
          />
          <label htmlFor="opt2" className="option" style={{ color: "#FAF8D4", fontSize: '12px', marginTop: "0px" }}>
            Derived Open Source Satellite Datasets
          </label>
          <input
            className="selectopt"
            name="test"
            type="radio"
            id="opt3"
            checked={selTab === "opt3"}
            onChange={() => setTab("opt3")} 
          />

          <label htmlFor="opt3" className="option" style={{ color: "#FAF8D4", fontSize: '12px', marginTop: "0px" }}>
            High Resolution Disaster Satellite Datasets
          </label>
          {!window.location.pathname.startsWith("/MapBox") && (
            <>
             <input
            className="selectopt"
            name="test" selectedOption
            type="radio"
            id="opt4" Farm Health Graph Generation
            checked={selTab === "opt4"}
            onChange={() => userInfo.is_superuser ? setTab("opt4") : paymentPrompt()}
          />
          
          <label htmlFor="opt4" className="option" style={{ color: "#FAF8D4", fontSize: '12px', marginTop: "0px", display: 'flex', flexDirection: 'row' }}>
            <span style={{ marginRight: '5px' }}>Perform Map Segmentation</span>
            <i className="fa-solid fa-crown" style={{ color: 'white', textShadow: '0 0 3px white', fontSize: '15px' }}></i>
          </label>
          <input
            className="selectopt"
            name="test"
            type="radio"
            id="opt5"
            checked={selTab === "opt5"}
            onChange={() => userInfo.is_superuser ? setTab("opt5") : paymentPrompt()}
          />
          <label htmlFor="opt5" className="option" style={{ color: "#FAF8D4", fontSize: '12px', marginTop: "0px", display: 'flex', flexDirection: 'row' }}>
            <span style={{ marginRight: '5px' }}>Perform Object Detection</span>
            <i className="fa-solid fa-crown" style={{ color: 'white', textShadow: '0 0 3px white', fontSize: '15px' }}></i>
          </label>

          <input
            className="selectopt"
            name="test"
            type="radio"
            id="opt6"
            checked={selTab === "opt6"}
            onChange={() => setTab("opt6")}
          />
          <label htmlFor="opt6" className="option" style={{ color: "#FAF8D4", fontSize: '12px', marginTop: "0px" }}>
            Farm Health Graph Generation
          </label>
         
          <input
            className="selectopt"
            name="test"
            type="radio"
            id="opt8"
            checked={selTab === "opt8"}
            onChange={() =>  setTab("opt8")}
          />
          <label htmlFor="opt8" className="option" style={{ color: "#FAF8D4", fontSize: '12px', marginTop: "0px", display: 'flex', flexDirection: 'row' }}>
            <span style={{ marginRight: '5px' }}>Automated Actions</span>
           
          </label>
          <input
            className="selectopt"
            name="test"
            type="radio"
            id="opt9"
            checked={selTab === "opt9"}
            onChange={() =>  setTab("opt9")}
          />
          <label htmlFor="opt9" className="option" style={{ color: "#FAF8D4", fontSize: '12px', marginTop: "0px", display: 'flex', flexDirection: 'row' }}>
            <span style={{ marginRight: '5px' }}>Climate and Weather Dataset</span>
           
          </label>
          <input
            className="selectopt"
            name="test"
            type="radio"
            id="opt10"
            checked={selTab === "opt10"}
            onChange={() =>  userInfo.is_superuser ? setTab("opt10") : paymentPrompt()}
          />
          <label htmlFor="opt10" className="option" style={{ color: "#FAF8D4", fontSize: '12px', marginTop: "0px", display: 'flex', flexDirection: 'row' }}>
            <span style={{ marginRight: '5px' }}>Ship Detection using SAR</span>
           
          </label>
            </>
          )}
         

        </div>
        <Template>
          {selTab === "opt1" && (
            <>
              <Sat toFetch="open" />
            </>
          )}
          {selTab === "opt2" && (
            <>
              <Sat toFetch="derive" />
            </>
          )}
          {selTab === "opt3" && (
            <>
              <Maxar />
            </>
          )}
          {selTab === "opt4" && (
            <>
              <Segment />
            </>
          )}
          {selTab === "opt5" && (
            <>
              <OD />
            </>
          )}
          {selTab === "opt6" && (
            <>
              <NDVI />
            </>
          )}
          {/* {selTab === "opt7" && (
                  <>
                     <Ship />
                  </>
                )} */}
         
           {selTab === "opt8" && (
            <>
              <Process />
            </>
            
          )}
          {selTab === "opt9" && (
            <>
              <Sat  toFetch="weather"/>
            </>
            
          )}
           {selTab === "opt10" && (
            <>
              <Ship/>
            </>
            
          )}

        </Template>
      

      </div>


    </>
  )
}

export default Actions