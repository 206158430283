import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="policy-container">
      <h1>Privacy Policy</h1>
      <p>
        This Privacy Policy describes how Vasundharaa Geo Technologies Private Limited and its affiliates
        (collectively "Vasundharaa Geo Technologies Private Limited, we, our, us") collect, use, share,
        protect or otherwise process your information/personal data through our website portal.vasundharaa.in
        (hereinafter referred to as Platform). Please note that you may be able to browse certain sections of the
        Platform without registering with us. We do not offer any product/service under this Platform outside
        India, and your personal data will primarily be stored and processed in India. By visiting this Platform,
        providing your information, or availing of any product/service offered on the Platform, you expressly agree
        to be bound by the terms and conditions of this Privacy Policy, the Terms of Use, and the applicable
        service/product terms and conditions, and agree to be governed by the laws of India, including but not
        limited to the laws applicable to data protection and privacy. If you do not agree, please do not use or
        access our Platform.
      </p>

      <h2>Collection</h2>
      <p>
        We collect your personal data when you use our Platform, services, or otherwise interact with
        us during the course of our relationship. Some of the
        information that we may collect includes but is not limited to personal data/information provided to us
        during sign-up/registering or using our Platform, such as name, date of birth, address, telephone/mobile
        number, email ID, and/or any such information shared as proof of identity or address. Some of the
        sensitive personal data may be collected with your consent, such as your bank account or credit or debit
        card information or biometric information such as your facial features or physiological information (in order to enable certain features when opted for, available on the
        Platform) etc. All of the above being in accordance with applicable law(s). You always have the option to
        not provide information by choosing not to use a particular service or feature on the Platform. We may
        track your behavior, preferences, and other information that you choose to provide on our Platform.
      </p>

      <h2>Usage</h2>
      <p>
        We use personal data to provide the services you request. To the extent we use your personal data
        to market to you, we will provide you the ability to opt-out of such uses. We use your personal data to
        assist sellers and business partners in handling and fulfilling orders, enhance customer experience,
        resolve disputes, troubleshoot problems, inform you about online and offline offers, products, services, and updates, customize your experience, detect and protect us against error, fraud, and other criminal
        activity, enforce our terms and conditions, conduct marketing research, analysis and surveys, and as
        otherwise described to you at the time of collection of information.
      </p>

      <h2>Sharing</h2>
      <p>
        We may share your personal data internally within our group entities, our other corporate
        entities, and affiliates to provide you access to the services and products offered by them. These entities
        and affiliates may market to you as a result of such sharing unless you explicitly opt-out. We may
        disclose personal data to third parties such as sellers, business partners, third-party service providers
        including logistics partners, prepaid payment instrument issuers, third-party reward programs, and other
        payment options chosen by you. These disclosures may be required for us to provide you access to our services and
        products, comply with our legal obligations, enforce our user agreement, facilitate
        marketing and advertising activities, or prevent, detect, mitigate, and investigate fraudulent or illegal
        activities related to our services.
      </p>

      <h2>Security Precautions</h2>
      <p>
        To protect your personal data from unauthorized access or disclosure, loss, or misuse, we adopt
        reasonable security practices and procedures. Once your information is in our possession or whenever you
        access your account information, we adhere to our security guidelines to protect it against unauthorized
        access and offer the use of a secure server. However, the transmission of information is not completely
        secure for reasons beyond our control. By using the Platform, the users accept the security implications of
        data transmission over the internet and the World Wide Web, which cannot always be guaranteed as
        completely secure.
      </p>

      <h2>Data Deletion and Retention</h2>
      <p>
        You have an option to delete your account by visiting your profile and
        settings on our Platform. This action would result in losing all information related to your account.
        We may refuse or delay the deletion of the account if there is a pending grievance, claim, or pending shipment. We
        retain your personal data for as long as necessary for the purposes it was collected for or as required by
        applicable law. In anonymized form, your data may be retained for research purposes.
      </p>

      <h2>Your Rights</h2>
      <p>
        You may access, rectify, and update your personal data directly through the functionalities
        provided on the Platform.
      </p>

      <h2>Consent</h2>
      <p>
        By visiting our Platform or by providing your information, you consent to the collection, use,
        storage, disclosure, and otherwise processing of your information in accordance with this
        Privacy Policy. If you disclose to us any personal data relating to other people, you represent that you
        have the authority to do so and permit us to use the information in accordance with this Privacy Policy.
      </p>

      <h2>Changes to this Privacy Policy</h2>
      <p>
        Please check our Privacy Policy periodically for changes. We may
        update this Privacy Policy to reflect changes in our information practices. We may notify you about significant changes to the Privacy Policy, as may be required by applicable
        laws.
      </p>

      <h2>Grievance Officer</h2>
      <p>
        Yuvraj Joshi<br />
        Lead Engineer<br />
        Vasundharaa Geo Technologies Private Limited, Pune, India<br />
        Contact us: service@dharaatech.in<br />
        Phone:  8379058188 <br/> 
        Time: Monday - Friday (9:00 - 18:00)
      </p>
    </div>
  );
};

export default PrivacyPolicy;
