import React from "react";


function Verified(){
    return (
        <>
        <p style={{color:"white"}}>Email Verified</p>
        <p  style={{color:"white"}}>Click <a href="/login">here</a> to login or continue </p>
        </>
    )
}

export default Verified