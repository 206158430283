import React, { useEffect, useState, useContext, useRef } from 'react';
import Markdown from 'react-markdown';
import files from '../static';
import HOST from "../host";
import { GlobalContext } from '../../App';
import { logToServer } from '../logger';

export default function Migrate({ layers, setMigrate }) {
    const { userInfo, organizationProjects, userProjects } = useContext(GlobalContext);
    const [isNew, setIsNew] = useState(false);
    const [selectedLayers, setSelectedLayers] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const exclude = ["Panvel", "panvel", "survey", "Jeevit", "Agrani", "Malegaon", "Manyad", "Pune", "Waterbody-Collection", "pune-demo", "satara", "Raigad-Landslide-Hazard-Assesment", "Kolhapur-Flood-Assesment", "Water-Impact-of-water-on-Agri-&-Livestock", "Kolhapur-Forest-Fire-Assesment", "Avalpoondurai-Crop-Classification", "Assam-Flood-2023", "Mines-in-Meghalaya", "Barpeta"];
    
    const handleCheckboxChange = (event, layerInfo) => {
        const isChecked = event.target.checked;
        const [layerName, layerId] = layerInfo;
        if (isChecked) {
            setSelectedLayers(prevState => [...prevState, { name: layerName, id: layerId }]);
        } else {
            setSelectedLayers(prevState => prevState.filter(layer => layer.id !== layerId));
        }
    };

    const handleProjectChange = (event) => {
        setSelectedProject(event.target.value);
    };

    const allProjects = [...userProjects, ...organizationProjects];

    const handleRadioChange = () => {
        setIsNew(!isNew);
        if (isNew) {
            setSelectedProject(null);
        }
    };

    async function migrate() {
        logToServer("info", `Migration started ${ selectedLayers, selectedProject}`); // Log migration start
        let projectName = selectedProject;

        if (isNew) {
            projectName = window.prompt("Please enter the new project name:");
            if (!projectName) {
                logToServer("error", "Migration failed: Project name required for new project"); // Log error

                alert("Project name is required for a new project.");
                return;
            }
        }

        if ((isNew || selectedProject) && selectedLayers.length) {
            try {
                const res = await fetch(`${HOST}/migrate-project/${isNew ? `newNAME${projectName}` : selectedProject}`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ data: { acts: selectedLayers} })
                });
                if (res.status === 400) {
                    logToServer("error", "Migration failed: Unexpected error occurred (400)"); // Log error
                    alert("Unexpected Error Occured. Please try again");
                } else if (res.status === 200) {
                    logToServer("info", "Migration successful: Project updated successfully"); // Log success
                    alert("Project updated successfully");
                    const event = new Event('added-to-pro');
                    document.dispatchEvent(event);
                }else if (res.status===201){
                    logToServer("error", "Migration failed: Only 10 layers allowed (201)"); // Log error
                    alert("Only 10 layers allowed")
                    return
                }
            } catch (e) {
                logToServer("error", "Migration failed: Unexpected error occurred"); // Log error
                alert("Unexpected Error Occured. Please try again");
            }
        } else {
            logToServer("error", "Migration failed: No layers or project selected"); // Log error
            alert("Please select at least one layer and a project");
        }
    }

    return (
        <>
            <div style={{ backgroundColor: 'white', minWidth: '550px', minHeight: '550px', marginBottom: '3%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <i className="fas fa-times text-black" onClick={(e) => { e.preventDefault(); e.stopPropagation(); setMigrate(false); }} style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer' }}></i>
                <div style={{ maxWidth: '500px', padding: '20px', border: '1px solid #ccc', borderRadius: '5px' }}>
                    <p style={{ fontSize: '1.2rem', marginBottom: '20px', textAlign: 'center' }}>Select Layers to Migrate:</p>
                    {layers ? (
                        <>
                            {layers.map((layer) => (
                                <div key={layer.id} style={{ marginBottom: '10px' }}>
                                    <input
                                        type="checkbox"
                                        id={`layer-${layer.id}`}
                                        className="form-check-input check-map"
                                        checked={selectedLayers.some(item => item.id === layer.id)}
                                        onChange={(event) => handleCheckboxChange(event, [layer.name, layer.id])}
                                        style={{ marginRight: '10px',borderColor:"black" }}
                                    />
                                    <label htmlFor={`layer-${layer.id}`}>{layer.name}</label>
                                </div>
                            ))}
                        </>
                    ) : (null)}
                    <select value={selectedProject} className="form-select border-0 mt-3" onChange={handleProjectChange} style={{ width: '100%', marginBottom: '20px' }} disabled={isNew}>
                        <option value="">Select a Project</option>
                        {allProjects.map(project => (
                            !exclude.includes(project.name) && (
                                <option key={project.id} value={project.id}>{project.name}</option>
                            )
                        ))}
                    </select>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                        <button onClick={(e) => migrate()} style={{ padding: '10px 20px', backgroundColor: '#007bff', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer', marginRight: '10px' }}>Migrate</button>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <input type="checkbox" id="new-project" className="form-check-input" disabled={userInfo.org_name === "global" && userProjects.length===1} checked={isNew} onChange={() => setIsNew(!isNew)} style={{ marginRight: '5px' }} />
                            <label htmlFor="new-project" style={{ marginBottom: '0' }}>New Project</label>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
