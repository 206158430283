import React, { useState } from "react";
import CreateSurveyForm from "./CreateSurveyForm";
import ViewSurveyForm from "./ViewSurveyForm";

const DataInput = ({ id }) => {
  const [isCreateMode, setIsCreateMode] = useState(false); // State to toggle between create and view modes

  const toggleMode = () => {
    setIsCreateMode((prevMode) => !prevMode); // Toggle between true (create) and false (view)
  };

  return (
    <div>
      <div style={{ marginBottom: '2vh' ,display:'inline-block'}}>
        <div className="container"style={{overflow:'hidden'}}>
          <button
            onClick={toggleMode}
            style={{
              ...buttonStyle,
              backgroundColor: isCreateMode ? '#2c3e50' : '#2c3e50',
            }}
          >
            {"Create Survey"}

          </button>
        </div>
      </div>

      {<ViewSurveyForm id={id} />}
      {isCreateMode && (
        <CreateSurveyForm id={id} setIsCreateMode={setIsCreateMode}/>
      )}
    </div>
  );
};

const buttonStyle = {
  fontSize: '16px',
  backgroundColor: '#007bff',
  color: '#fff',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
};

export default DataInput;