import React, { useState, useContext, useEffect } from "react";
import { GlobalContext } from "../../App";
import { NavLink, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import files from "../static";
import HOST from "../host";
import Cookies from "js-cookie";
import { logToServer } from "../logger";
export default function Login() {
  const navigate = useNavigate();
  const [member_email, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { setUserInfo, userInfo, getCsrfToken } = useContext(GlobalContext);
  const [loader, setLoader] = useState(false);
  function redirect() {
    navigate("/forget-password")
  }
  function togglePasswordVisibility() {
    setShowPassword(!showPassword);
  }
  const loginUser = async (e) => {
    e.preventDefault();
    setLoader(true);
    if (!member_email || !password) {
      toast.warn("Please enter both email and password.");
      setLoader(false);
      return;
    }
    const formData = {
      "member_email": member_email,
      "password": password,
    }
    try {
      const res = await fetch(`${HOST}/login/`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(formData),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-CSRFToken': await getCsrfToken(),
        },
      });
      const data = await res.json();
      if (res.status === 400 || res.status === 500 || !data || data.error) {
        toast.error(`${data.error}`);
        logToServer('error', `${data.error}`);
      } else if (res.status === 200) {
        toast.success(`Welcome ${data.user_info.name}`);
        logToServer('info', 'login Successfully');
        setUserInfo(data.user_info);
      }
    } catch (e) {
      logToServer('error', `${e}`);
      toast.error("Invalid credentials");
    } finally {
      setLoader(false); // Ensure loader is hidden regardless of success or failure
    }
  };
  return (
    <>
      <div className="login-layout" >
        <div className="form-div" >
          <form className="forml">
            <div style={{ display: 'flex', flexDirection: 'row', margin: '0%' }}>
              {loader && (
                <>
                  <div style={{ flex: '1', position: 'relative', marginLeft: '-10%', opacity: '1', zIndex: '1000' }}>
                    <div className="lds-dual-ring">
                      <i className="fa-solid fa-globe"></i>
                    </div>
                  </div>
                </>
              )}
              <img src={`${process.env.PUBLIC_URL}/${files}vgtlogo.png`} alt="logo" width='250px' height='100px' style={{ flex: '11', margin: '0px 0px 25px 25px' }} />
            </div>
            <div class="input-wrapper">
              <input
                type="email"
                className="input border-0"
                style={{ marginBottom: '0', border: 'none', opacity: loader ? '0.3' : '1' }}
                value={member_email}
                onChange={(e) => setUserEmail(e.target.value)}
                placeholder="Enter email"
                required
              />
            </div>
            <br />
            <br />
            <div className="password-wrapper">
              <div class="input-wrapper">
                <input
                  type={showPassword ? "text" : "password"}
                  className="input border-0"
                  style={{ marginBottom: '0', border: 'none', opacity: loader ? '0.3' : '1' }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter password"
                  required
                />
                <button style={{backgroundColor:"#272727"}} type="button" onClick={togglePasswordVisibility}>
                  {showPassword ? (
                    <i className="fa fa-eye-slash"></i>
                  ) : (
                    <i className="fa fa-eye"></i>
                  )}
                </button>
              </div>
            </div>
            <br />
            <br />
            <button
              type="submit"
              className="login"
              id="login-btn"
              value="Log in"
              onClick={(e) => loginUser(e)}
              style={{ opacity: loader ? '0.3' : '1' }}
            >
              Log in
            </button>
            <ToastContainer position="bottom-right" theme="colored" draggable={false} />
            <NavLink to="/signup" className="register" style={{ opacity: loader ? '0.3' : '1' }}>
              Create an Account
            </NavLink>
            <NavLink to="/forget-password" className="register" style={{ opacity: loader ? '0.3' : '1' }}>
              Forgot Password?
            </NavLink>
          </form>
        </div >
      </div >
    </>
  );
}