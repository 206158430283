import React from 'react';
import datasetInfoDictionary from './Info';

export default function InfoWindow({ selectedDataset }) {
  return (
    <div >
      {selectedDataset && (
        <div>
          {datasetInfoDictionary[selectedDataset]}
        </div>
      )}
    </div>
  );
}