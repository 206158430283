import React, { useState, useEffect, useRef } from 'react';
import FileSaver from 'file-saver';
import HOST from '../host';
import "./viewsurvey.css";
import { logToServer } from '../logger';

function ViewSurveyForm({ id }) {
  const [surveyData, setSurveyData] = useState([]);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [dataInputs, setDataInputs] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [isResponseVisible, setIsResponseVisible] = useState(false);
  const [isViewModel, setIsViewModel] = useState(false);
  const [selectedResponse, setSelectedResponse] = useState(null);
  const [userEmails, setUserEmails] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'ascending' });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentResponsePage, setCurrentResponsePage] = useState(1);
  const [totalResponsePages, setTotalResponsePages] = useState(1);
  const itemsPerPage = 10;

  const fetchSurveyData = async () => {
    
    try {
      const response = await fetch(`${HOST}/view_survey_field_data/${id}`);
      if (!response.ok) {
        throw new Error('Failed to fetch survey data');
      }
      const data = await response.json();
      setSurveyData(data.survey_data);
      console.log(data.survey_data);
      
      setTotalPages(Math.ceil(data.survey_data.length / itemsPerPage));
      logToServer('info', 'Fetching survey data');
    } catch (error) {
      logToServer('error', `${error}`);
      setError('Failed to fetch survey data. Please try again later.');
    }
  };


  const fetchSurveyResponses = async (id) => {
    try {
      const response = await fetch(`${HOST}/surveyfield/${id}/`);
      if (!response.ok) {
        throw new Error('Failed to fetch survey responses');
      }
      const data = await response.json();
      setResponseData(data.survey_data.response);
      setIsResponseVisible(true);
      setTotalResponsePages(Math.ceil(data.survey_data.response.length / itemsPerPage));
      logToServer('info', 'Fetching survey response successfully');
    } catch (error) {
      logToServer('error', `${error}`);
      setError('Failed to fetch survey responses. Please try again later.');
    }
  };

  useEffect(() => {
    fetchSurveyData();
    fetchUserEmails();
  }, []);

  const handleDelete = async (id) => {
    // Show confirmation prompt
    const confirmed = window.confirm('Are you sure you want to delete this survey?');
  
    // If the user cancels, exit the function
    if (!confirmed) {
      return;
    }
  
    try {
      const response = await fetch(`${HOST}/delete_survey/${id}`, {
        method: 'DELETE', // Specify the method if needed
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to delete survey data');
      }
  
      // Update the state after successful deletion
      setSurveyData(surveyData.filter(survey => survey.id !== id));
      setTotalPages(Math.ceil((surveyData.length - 1) / itemsPerPage));
      logToServer('info', 'Deleted survey data successfully');
    } catch (error) {
      logToServer('error', `${error}`);
      setError('Failed to delete survey data. Please try again later.');
    }
  };
  

  const handleEdit = async (survey) => {
    setSelectedSurvey(survey);
    setDataInputs(survey.data_inputs);
    setIsModalOpen(true);
  };

  const handleView = (response) => {
    setSelectedResponse(response);
    setIsViewModel(true);
  };

  const handleViewResponses = async (id) => {
    await fetchSurveyResponses(id);
  };

  const handleAcknowledge = () => {
    setIsResponseVisible(false);
  };

  const handleSave = async () => {
    try {
      const response = await fetch(`${HOST}/edit_survey_data_inputs/${selectedSurvey.id}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ data_inputs: dataInputs, selected_email: selectedEmails })
      });
      if (!response.ok) {
        throw new Error('Failed to update survey data inputs');
      }
      setIsModalOpen(false);
      fetchSurveyData();
      logToServer('info', 'Edit survey form successfully');
    } catch (error) {
      logToServer('error', `${error}`);
      setError('Failed to update survey data inputs. Please try again later.');
    }
  };

  const handleInputChange = (index, key, value) => {
    const newDataInputs = [...dataInputs];
    newDataInputs[index][key] = value;
    setDataInputs(newDataInputs);
  };

  const handleOptionChange = (inputIndex, optionIndex, field, value) => {
    const newDataInputs = [...dataInputs];
    newDataInputs[inputIndex].options[optionIndex][field] = value;
    setDataInputs(newDataInputs);
  };

  const handleAddOption = (inputIndex) => {
    const newDataInputs = [...dataInputs];
    newDataInputs[inputIndex].options.push({ name: '', value: '' });
    setDataInputs(newDataInputs);
  };

  const handleDeleteOption = (inputIndex, optionIndex) => {
    const newDataInputs = [...dataInputs];
    newDataInputs[inputIndex].options.splice(optionIndex, 1);
    setDataInputs(newDataInputs);
  };

  const handleDownloadCsv = async (id) => {
    try {
      const response = await fetch(`${HOST}/survey/${id}/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'text/csv'
        }
      });
      if (!response.ok) {
        throw new Error('Failed to download CSV');
      }
      const blob = await response.blob();
      FileSaver.saveAs(blob, `survey_${id}_response.csv`);
      logToServer('info', 'Downloading CSV successfully');
    } catch (error) {
      logToServer('error', `${error}`);
      setError('Failed to download CSV. Please try again later.');
    }
  };

  const handleAddField = () => {
    const newField = {
      title: '',
      type: '',
      options: []
    };
    setDataInputs([...dataInputs, newField]);
  };

  const handleDeleteField = (index) => {
    const newDataInputs = [...dataInputs];
    newDataInputs.splice(index, 1);
    setDataInputs(newDataInputs);
  };

  const isValidUrl = (string) => {
    try {
      const url = new URL(string);
      const truncatedUrl = `${url.protocol}//${url.hostname}${url.pathname.length > 20 ? url.pathname.substring(0, 20) + '...' : url.pathname}`;
      return truncatedUrl;
    } catch (_) {
      return false;
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const fetchUserEmails = () => {
    fetch(`${HOST}/fetch_emails/${id}`, {
      method: 'GET',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch user emails');
        }
        return response.json();
      })
      .then((data) => {
        setUserEmails(data);
        logToServer('info', 'Fetched user emails successfully');
      })
      .catch((error) => {
        logToServer('error', `${error}`);
      });
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const toggleEmail = (email) => {
    const isSelected = selectedEmails.includes(email);
    if (isSelected) {
      setSelectedEmails(selectedEmails.filter((selected) => selected !== email));
    } else {
      setSelectedEmails([...selectedEmails, email]);
    }
  };

  const filteredSurveyData = surveyData.filter((survey) =>
    survey.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    (isResponseVisible &&
      responseData.some(
        (response) =>
          response.firstname.toLowerCase().includes(searchQuery.toLowerCase()) ||
          response.lastname.toLowerCase().includes(searchQuery.toLowerCase())
      ))
  );

  const sortSurveyData = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });

    const sortedData = [...surveyData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    setSurveyData(sortedData);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? '▲' : '▼';
    }
    return ' ';
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const paginateResponses = (pageNumber) => {
    setCurrentResponsePage(pageNumber);
  };

  const currentItems = filteredSurveyData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  const currentResponseItems = responseData.slice((currentResponsePage - 1) * itemsPerPage, currentResponsePage * itemsPerPage);

  return (
    <div style={{ height: '70vh' }}>
      <div style={{ display: 'flex', flexDirection: "row-reverse" }}>
        <input
          type="text"
          placeholder="Search Forms..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ marginLeft: '10px', padding: '5px', borderRadius: '3px', border: '1px solid #ccc', marginBottom: '8px' }}
        />
      </div>

      {error && <div>Error: {error}</div>}

      {!isResponseVisible && (
        <div>
        <div style={{ height: '68vh', overflowY: 'scroll' }}>
          <table className="custom-table1">
            <thead style={{ position: 'sticky', top: '0', zIndex: '1', backgroundColor: '#f5f5f5' }}>
              <tr>
                {/* <th onClick={() => sortSurveyData('id')}>
                  ID {getSortIcon('id')}
                </th> */}
                <th onClick={() => sortSurveyData('user')}>
                  UserName {getSortIcon('user')}
                </th>
                <th onClick={() => sortSurveyData('name')}>
                  Form Name {getSortIcon('name')}
                </th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(currentItems) && currentItems.length > 0 ? (
                currentItems.map((survey, index) => (
                  
                  
                  <React.Fragment key={survey.id}>
                    <tr>
                      {/* <td>{survey.id}</td> */}
                      <td>{survey.user}</td>
                      <td>{survey.name}</td>
                      <td>
                        <button onClick={() => handleDelete(survey.id)} className='btn_delete'>
                          <i className="fa fa-trash"></i>
                        </button>
                        <button onClick={() => handleEdit(survey)} className='btn_edit'> 
                          <i className="fa fa-edit"></i>
                        </button>
                        <button onClick={() => handleViewResponses(survey.id)} className='btn_view'>
                          <i className="fa fa-eye"></i>
                        </button>
                        <button onClick={() => handleDownloadCsv(survey.id)} className='btn_download'>
                          <i className="fa fa-download"></i>
                        </button>
                      </td>
                    </tr>
                  </React.Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan="4">No survey data available.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="pagination">
            <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>&lt;</button>
            {currentPage > 3 && <button onClick={() => paginate(1)}>1</button>}
            {currentPage > 4 && <span>...</span>}
            {currentPage > 2 && <button onClick={() => paginate(currentPage - 2)}>{currentPage - 2}</button>}
            {currentPage > 1 && <button onClick={() => paginate(currentPage - 1)}>{currentPage - 1}</button>}
            <button className="current-page">{currentPage}</button>
            {currentPage < totalPages && <button onClick={() => paginate(currentPage + 1)}>{currentPage + 1}</button>}
            {currentPage < totalPages - 1 && <button onClick={() => paginate(currentPage + 2)}>{currentPage + 2}</button>}
            {currentPage < totalPages - 3 && <span>...</span>}
            {currentPage < totalPages - 2 && <button onClick={() => paginate(totalPages)}>{totalPages}</button>}
            <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>&gt;</button>
          </div>
        </div>
      )}

      {isResponseVisible && (
        <div>
          <div style={{ height: '5vh' }}>
            <button onClick={handleAcknowledge} style={{ position: 'sticky', top: '0', zIndex: '1', backgroundColor: '#2c3e50' }}>
              Back
            </button>
          </div>
          <div style={{ height: '63vh', overflowY: 'scroll' }}>
            <table className="custom-table1">
              <thead style={{ position: 'sticky', top: '0', zIndex: '1', backgroundColor: '#f5f5f5' }}>
                <tr>
                  <th >
                   Serial ID 
                  </th>
                  {/* <th onClick={() => sortSurveyData('user_id')}>
                    User ID {getSortIcon('user_id')}
                  </th> */}
                  <th onClick={() => sortSurveyData('name')}>
                    Name {getSortIcon('name')}
                  </th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(currentResponseItems) && currentResponseItems.length > 0 ? (
                  currentResponseItems
                    .filter((response) =>
                      `${response.firstname} ${response.lastname}`.toLowerCase().includes(searchQuery.toLowerCase())
                    )
                    .map((response, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        {/* <td>{response.user_id}</td> */}
                        <td>{response.firstname} {response.lastname}</td>
                        <td>
                          <button onClick={() => handleView(response)} className='btn_view'>
                            <i className="fa fa-eye"></i>
                          </button>
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan="4">No responses available.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="pagination">
              <button onClick={() => paginateResponses(currentResponsePage - 1)} disabled={currentResponsePage === 1}>&lt;</button>
              {currentResponsePage > 3 && <button onClick={() => paginateResponses(1)}>1</button>}
              {currentResponsePage > 4 && <span>...</span>}
              {currentResponsePage > 2 && <button onClick={() => paginateResponses(currentResponsePage - 2)}>{currentResponsePage - 2}</button>}
              {currentResponsePage > 1 && <button onClick={() => paginateResponses(currentResponsePage - 1)}>{currentResponsePage - 1}</button>}
              <button className="current-page">{currentResponsePage}</button>
              {currentResponsePage < totalResponsePages && <button onClick={() => paginateResponses(currentResponsePage + 1)}>{currentResponsePage + 1}</button>}
              {currentResponsePage < totalResponsePages - 1 && <button onClick={() => paginateResponses(currentResponsePage + 2)}>{currentResponsePage + 2}</button>}
              {currentResponsePage < totalResponsePages - 3 && <span>...</span>}
              {currentResponsePage < totalResponsePages - 2 && <button onClick={() => paginateResponses(totalResponsePages)}>{totalResponsePages}</button>}
              <button onClick={() => paginateResponses(currentResponsePage + 1)} disabled={currentResponsePage === totalResponsePages}>&gt;</button>
            </div>
        </div>
      )}
      {isModalOpen && (
        <div className="modal" style={{ display: 'block', paddingRight: '5%', paddingLeft: '5%' ,width:'fit-content'}}>
          <div style={{ backgroundColor: "white", padding: "2vh",  borderRadius: '12px',height:'60vh' }}>
            <div style={{ display: 'flex', flexDirection: 'row-reverse', marginLeft: '95%' }}>
              <h3 onClick={() => setIsModalOpen(false)} style={{ cursor: 'pointer', color: 'red' }}>X</h3>
            </div>
            <div className="modal-content" style={{ overflowY: 'scroll', height: '95%' }}>
              <h2 style={{ textAlign: 'center' }}>Edit Survey Data Inputs</h2>
              <div>
                <h4>Select User Emails:</h4>
                <div style={{ position: 'relative' }}>
                  <button onClick={toggleDropdown} style={{ marginBottom: '10px' }}>
                    {showDropdown ? 'Hide' : 'Show'} Emails
                  </button>
                  {showDropdown && (
                    <div ref={dropdownRef} className="email-dropdown">
                      {userEmails.map((email, index) => (
                        <label key={index} style={{ display: 'block' }}>
                          <input
                            type="checkbox"
                            checked={selectedEmails.includes(email)}
                            onChange={() => toggleEmail(email)}
                          />{' '}
                          {email}
                        </label>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              {dataInputs.map((input, index) => (
                <div key={index} style={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
                  <div style={{ flex: 1 }}>
                    <h6 style={{ marginBottom: '5px' }}>Field {index + 1}</h6>
                    <label style={{ marginRight: '10px' }}>Title:</label>
                    <input
                      type="text"
                      name="title"
                      value={input.title}
                      onChange={(e) => handleInputChange(index, 'title', e.target.value)}
                      style={{
                        padding: '5px',
                        fontSize: '14px',
                        border: '1px solid #ccc',
                        borderRadius: '5px',
                      }}
                    />
                    <label style={{ marginLeft: '10px', marginRight: '10px' }}>Type:</label>
                    <select
                      name="type"
                      value={input.type}
                      onChange={(e) => handleInputChange(index, 'type', e.target.value)}
                      style={{
                        padding: '5px',
                        fontSize: '14px',
                        border: '1px solid #ccc',
                        borderRadius: '5px',
                      }}
                    >
                      <option value="">Select Type</option>
                      <option value="text">Text</option>
                      <option value="number">Number</option>
                      <option value="date">Date</option>
                      <option value="time">Time</option>
                      <option value="location">Location</option>
                      <option value="media">Media</option>
                      <option value="radio">Single Option</option>
                      <option value="checkbox">Multiple Option</option>
                    </select>
                    <button onClick={() => handleDeleteField(index)} style={{ marginLeft: '10px' }}>
                      Delete Field
                    </button>

                    {input.type === 'radio' && (
                      <div style={{ marginTop: '5px', marginLeft: '2vh' }}>
                        <h6 style={{ marginBottom: '5px' }}>Options:</h6>
                        {input.options.map((option, optionIndex) => (
                          <div key={optionIndex} style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                            <input
                              type="radio"
                              checked={option.value === input.selectedOption}
                              onChange={() => handleOptionChange(index, optionIndex, 'value', option.value)}
                              style={{ marginRight: '5px' }}
                            />
                            <input
                              type="text"
                              value={option.name}
                              onChange={(e) => handleOptionChange(index, optionIndex, 'name', e.target.value)}
                              placeholder="Option Name"
                              style={{ marginRight: '5px' }}
                            />
                            <button
                              onClick={() => handleDeleteOption(index, optionIndex)}
                              style={{ marginLeft: '5px' }}
                            >
                              Delete Option
                            </button>
                          </div>
                        ))}
                        <button
                          onClick={() => handleAddOption(index)}
                          style={{ marginTop: '5px' }}
                        >
                          Add Option
                        </button>
                      </div>
                    )}

                    {input.type === 'checkbox' && (
                      <div style={{ marginTop: '5px', marginLeft: '2vh' }}>
                        <h6 style={{ marginBottom: '5px' }}>Options:</h6>
                        {input.options.map((option, optionIndex) => (
                          <div key={optionIndex} style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                            <input
                              type="checkbox"
                              checked={option.checked}
                              onChange={(e) => handleOptionChange(index, optionIndex, 'checked', e.target.checked)}
                              style={{ marginRight: '5px' }}
                            />
                            <input
                              type="text"
                              value={option.name}
                              onChange={(e) => handleOptionChange(index, optionIndex, 'name', e.target.value)}
                              placeholder="Option Name"
                              style={{ marginRight: '5px' }}
                            />
                            <button
                              onClick={() => handleDeleteOption(index, optionIndex)}
                              style={{ marginLeft: '5px' }}
                            >
                              Delete Option
                            </button>
                          </div>
                        ))}
                        <button
                          onClick={() => handleAddOption(index)}
                          style={{ marginTop: '5px' }}
                        >
                          Add Option
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ))}

              <div style={{ textAlign: 'center', marginTop: '10px' }}>
                <button onClick={handleAddField} style={{ marginTop: '10px' }}>
                  Add Field
                </button>
                <button onClick={handleSave}>Save</button>
                <button onClick={() => setIsModalOpen(false)} style={{ marginLeft: '10px' }}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
          </div> 
     
      )}

      {isViewModel && (
        <div className="modal" style={{ display: 'block' ,marginTop:'240px'}}>
          <div style={{ marginLeft: '30%', marginRight: '30%' }}>
            <div className="modal-content">
              <h2>Survey Response Details</h2>
              <div style={{ marginBottom: '15px' }}>
                <h4>Response from: {selectedResponse.firstname} {selectedResponse.lastname}</h4>
                <p>User ID: {selectedResponse.user_id}</p>
                {Object.entries(selectedResponse.response).map(([key, value]) => (
  <div key={key}>
    <strong>{key}:</strong> 
    {typeof value === 'string' && value.includes('media/survey_response') ? (
      <img 
        src={`http://localhost:8000/${value}`} 
        alt={key} 
        style={{ maxWidth: '100px', maxHeight: '100px' }}
      />
    ) : typeof value === 'string' && isValidUrl(value) ? (
      <a href={value} target="_blank" rel="noopener noreferrer">
        {isValidUrl(value)}
      </a>
    ) : (
      typeof value === 'object' ? JSON.stringify(value) : value
    )}
  </div>
))}
              </div>
              <button
                onClick={() => setIsViewModel(false)}
                style={{
                  marginTop: '10px',
                  padding: '10px 20px',
                  fontSize: '16px',
                  backgroundColor: '#f44336',
                  color: 'white',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  marginLeft: '30%',
                  marginRight: '30%'
                }}
              >
                Close
              </button>
            </div>
         
          </div>
        </div>
      )}
    </div>
  );
}

export default ViewSurveyForm;