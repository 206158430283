import React from 'react';
import { useState } from 'react';
import HOST from "../host";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./otp.css"; // Import the CSS file where you define the styles
import { logToServer } from '../logger';
function useForceUpdate() {
    const [, setValue] = useState(0);

    return () => setValue(value => value + 1);
} 

function OTP({setReset}) {
  const [otp, setOTP] = useState("");
  const forceUpdate = useForceUpdate();

  const handleChange = (value) => {
    setOTP(value)
  };
  async function verify(e){
    e.preventDefault();
    if(otp && String(otp).length===4){
        const key = sessionStorage.getItem('otpkey');
        const expirationTime = sessionStorage.getItem('otp_expiration');
        const currentTime = new Date().getTime();
        if (key && expirationTime) {
            if (currentTime > expirationTime) {
                sessionStorage.removeItem('otpkey');
                sessionStorage.removeItem('otp_expiration');
                toast.error("OTP has expired. Please try again");
                return
            }
        }else{
          toast.error("Unexpected error occured. Please resend OTP");
          return
        }
        const otpString = String(otp);
        try{
            const res = await fetch(`${HOST}/verify-otp/${otpString}/${key}`, {
              method: "POST",
              body: {},
            });
            
            if (res.status === 400) {
              toast.error("OTP could not be verified");
              logToServer('error','OTP could not be verified')
            } else if (res.status === 200) {
              toast.success(`OTP Verified`);
              logToServer('info','OTP Verified successfully')
              sessionStorage.removeItem('otpkey');
                sessionStorage.removeItem('otp_expiration');
              setReset(true)
            }
          }
          catch (e){
            toast.error("Invalid credentials");
            logToServer('error',`${e}`)
          }
    }else{
      toast.error("Please type OTP or type all 4 digits");
    }
  }
  
  return (
    <>
    <div className="container-fluid py-10">
    <div className="row justify-content-center">
      <div className="col-md-10">
        <div className="bg-white p-10 rounded-3 shadow-sm border">
          <div>
            <div className="row pt-4 pb-2 justify-content-center">
            <input  className="otp-input" required type="text" autoComplete="one-time-code" inputMode="numeric" maxLength="4" pattern="\d{4}" onChange={(e)=>handleChange(e.target.value)} value={otp}></input>
            </div>
           
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="row pt-5 justify-content-center">
              <div className="col-6">
                <button onClick={(e)=>(verify(e))} className="login"
                  id="back-btn">Verify</button>
              </div>
            </div>
  </>
  );
}

export default OTP;
