

import React, { useEffect, useState, useRef } from 'react';
import Calendar from 'react-calendar';
import HOST from '../../host';
import 'react-calendar/dist/Calendar.css';
import { logToServer } from '../../logger';

function Cal({ map, mapBox, selData, SetSDate, SDate, setloader, toFetch, both, limit, cloud, cloudValue }) {
  const [showmess, setmess] = useState(true);
  const [date, setDate] = useState(new Date());
  const abortController = useRef(null);
  const [hDate, SetHdate] = useState(null);
  const [dates, setDates] = useState([]);
  
  const onChange = (newDate) => {
    SetSDate(newDate);
  };

  async function GetDates(month, year, cloudvals = null) {
    if (map){
      console.log(map.getBounds())
    }
    const isMapZoomed = map && map.getZoom() >= 7;
    const isMapBoxZoomed = mapBox && mapBox.getZoom() >= 7;

    if (toFetch !== "weather" && selData !== "Daily Land Surface Temperature" && selData !== "Temperature above (2m)ground" && selData !== "MODIS RGB" && selData !== "Monthly Nighttime Data (VIIRS)" && !both) {
      if (isMapZoomed || isMapBoxZoomed) {
        setmess(false);
        setloader(true);
        let convertedObj =null
        if (isMapBoxZoomed){
          let obj=mapBox.getBounds()
          convertedObj = {
            '_southWest': {
              'lat': obj._sw.lat,
              'lng': obj._sw.lng
            },
            '_northEast': {
              'lat': obj._ne.lat,
              'lng': obj._ne.lng
            }
          };
        }else if (isMapZoomed){
          convertedObj=map.getBounds();
        }
        if (abortController.current) {
          try{
          abortController.current.abort();
          }catch{

          }
        }
        abortController.current = new AbortController();
        try {
          const response = await fetch(`${HOST}/get-dates/${toFetch}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              data: {
                month: month + 1,
                year: year,
                dataset: selData,
                extent: convertedObj,
                cloud: cloudvals
              }
            }),
            signal: abortController.current.signal,
          });
          const data = await response.json();
          if (data && data.day) {
            let datesList = data.day.map((ele) => new Date(ele));
            setDates(datesList);
          } else {
            setDates([]);
          }
          setloader(false);
        } catch (error) {
          console.error(error);
          if (error.name !== 'AbortError') {
            alert("Unexpected Error occurred. Please try again.");
            setloader(false);
          }
        }
      } else {
        setmess(true);
      }
    } else {
      SetHdate(null);
      setmess(false);
      setloader(false);
    }
  }
  
  useEffect(() => {
    if (toFetch !== "weather") {
      if (cloud) {
        GetDates(date.getMonth(), date.getFullYear(), cloudValue);
      } else {
        GetDates(date.getMonth(), date.getFullYear());
      }
    } else {
      setmess(false);
    }
  }, [selData, date, map, mapBox, cloud, cloudValue]);

  const handleViewChange = ({ activeStartDate }) => {
        let newDate = new Date(activeStartDate)
        setDate(newDate)
      };
    

  useEffect(() => {
    let timeoutId;

    const handleMoveEnd = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        if (cloud) {
          GetDates(date.getMonth(), date.getFullYear(), cloudValue);
        } else {
          GetDates(date.getMonth(), date.getFullYear());
        }
      }, 200);
    };

    if (map) {
      map.on("move", handleMoveEnd);
    }

    if (mapBox) {
      mapBox.on("move", handleMoveEnd);
    }

    return () => {
      clearTimeout(timeoutId);
      if (map) {
        map.off("move", handleMoveEnd);
      }
      if (mapBox) {
        mapBox.off("move", handleMoveEnd);
      }
    };
  }, [GetDates,map, mapBox, date, cloud, cloudValue]);

  const tileContent = ({ date, view }) => {
    const currentDate = new Date(date);
    if (dates.length > 0) {
      if (dates.some(d => d.toDateString() === currentDate.toDateString() && view === "month" && SDate && currentDate.toDateString() === SDate.toDateString())) {
        return "sel";
      }
      if (dates.some(d => d.toDateString() === currentDate.toDateString()) && view === "month") {
        return "highlight";
      }
    }
    if (SDate && currentDate.toDateString() === SDate.toDateString()) {
      return "sel";
    }
    return null;
  };

  return (
    <div>
      <style>{`
        .react-calendar {
          width: 100%;
          border: none;
          color: black;
          line-height: 1.125em;
          margin-bottom: 5px;
        }
        .react-calendar__month-view__weekdays__weekday abbr {
          color: gray;
        }
        .react-calendar__month-view__days__day abbr {
          color: black;
        }
        .react-calendar__year-view__months__month abbr {
          color: black;
        }
        .react-calendar__decade-view__years__year {
          color: black;
        }
        .react-calendar__century-view__decades__decade {
          color: black;
        }
        .react-calendar__navigation button {
          background-color: transparent;
          width: 44px;
          color: black;
          font-size: 15px;
        }
        .react-calendar__navigation__label {
          width: 112.47px;
          height: 44px;
        }
        .react-calendar__navigation {
          display: flex;
          height: 44px;
          margin-bottom: 1em;
        }
        .react-calendar__navigation button:enabled:hover,
        .react-calendar__navigation button:enabled:focus {
          background-color: transparent;
          color: black;
        }
        .highlight {
          background-color: yellow;
          color: black;
        }
        .react-calendar__tile {
          width: 3em;
          height: 3em;
          display: flex;
          color: black;
          justify-content: center;
          align-items: center;
        }
        .react-calendar__month-view__days__day--neighboringMonth,
        .react-calendar__decade-view__years__year--neighboringDecade,
        .react-calendar__century-view__decades__decade--neighboringCentury {
          color: #000000;
        }
        .react-calendar__year-view .react-calendar__tile,
        .react-calendar__decade-view .react-calendar__tile,
        .react-calendar__century-view .react-calendar__tile {
          padding: 2em 0.5em;
          color: black;
        }
        .react-calendar__tile:hover {
          color: black;
        }
        .react-calendar__tile--now {
          background-color: white !important;
        }
        .react-calendar__tile--active {
          background-color: white !important;
        }
        .sel {
          background-color: #50bcfa !important;
          color: black;
        }
        @media screen and (min-width: 550px) and (max-width: 1370px) {
          .react-calendar {
            max-width: 100%;
            background: white;
            color: black;
            border: 1px solid #a0a096;
            font-family: Arial, Helvetica, sans-serif;
            line-height: 1.125em;
          }
        }
      `}</style>

      {showmess ? (
        <p style={{ color: "white", textAlign: "center", fontSize: '12px' }}>Zoom to view data</p>
      ) : (
        <Calendar
          minDate={limit ? new Date(limit) : null}
          maxDate={toFetch !== "weather" ? new Date() : new Date("2024-05-29")}
          onClickDay={onChange}
          tileClassName={tileContent}
          onActiveStartDateChange={handleViewChange}
          value={date}
        />
      )}
    </div>
  );
}

export default Cal;
